import { IResourceComponentsProps, useTranslate, useList, useOne } from '@refinedev/core'
import { Edit, useForm, useSelect } from '@refinedev/antd'
import { Checkbox, Form, InputNumber, Select, } from 'antd'
import { IPaymentCascade, IGateway, IMerchant } from 'interfaces'
import { gatewayOptions } from 'components/GatewayOptions'

export const PaymentCascadeEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps, form } = useForm<IPaymentCascade>()

  const kind = Form.useWatch('kind', form)
  const merchant_id = Form.useWatch('merchant_id', form)

  const { data: merchantData } = useOne<IMerchant>({
    resource: 'merchants',
    //@ts-ignore
    id: merchant_id,
    queryOptions: {
      enabled: merchant_id != undefined,
    },
  })

  const { data: gatewaysData } = useList<IGateway>({
    resource: 'gateways',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
      {
        field: 'currency',
        operator: 'eq',
        value: merchantData?.data?.currency,
      },
      {
        field: 'direction',
        operator: 'eq',
        value: kind == 'payout' ? 'withdraw' : 'deposit',
      },
      {
        field: 'payment_type',
        operator: 'eq',
        value: ['payout', 'card', 'sbp', 'sbpqr', 'account', 'ecom', 'iban', 'qr', 'url', 'cvu', 'va', 'phone'],
      },
    ],
    pagination: {
      current: 1,
      pageSize: 1000,
    },
  })
  const gateways = gatewaysData?.data ?? []

  const { selectProps: merchantSelectProps } = useSelect<IMerchant>({
    resource: 'merchants',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          name="payment_system"
          rules={[{ required: true, message: '' },]}
        >
          <Select
            disabled
            options={[
              {
                label: t('payment_cascades.payment_system.spacepayments'),
                value: 'spacepayments',
              },
              {
                label: t('payment_cascades.payment_system.auris'),
                value: 'auris',
              }
            ]}
          />
        </Form.Item>
        <Form.Item
          name="kind"
          rules={[{ required: true, message: '' },]}
        >
          <Select
            disabled
            options={[
              {
                label: t('payment_cascades.kind.income'),
                value: 'income',
              },
              {
                label: t('payment_cascades.kind.payout'),
                value: 'payout',
              }
            ]}
          />
        </Form.Item>
        <Form.Item
          label={t('payment_cascades.form.traffic_percentage.label')}
          name="traffic_percentage"
          rules={[{ required: true, message: '' },]}
        >
          <InputNumber min={0} max={100} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          hidden
          name="all_merchants"
          valuePropName="checked"
          label={t('payment_cascades.fields.all_merchants')}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="merchant_id"
          label={t('payment_cascades.fields.merchant')}
          initialValue={formProps?.initialValues?.merchant?.id}
        >
          <Select {...merchantSelectProps} disabled />
        </Form.Item>
        <Form.Item
          name="all_gateways"
          valuePropName="checked"
          label={t('payment_cascades.fields.all_gateways')}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="gateway_id"
          label={t('payment_cascades.fields.gateway')}
          initialValue={formProps?.initialValues?.gateway?.id}
        >
          <Select
            allowClear
            showSearch
            options={gatewayOptions(gateways)}
            placeholder={t('agreements.form.gateway.placeholder')}
            filterOption={(input, option: any) =>
              (option?.desc ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
      </Form>
    </Edit >
  )
}
