import { IResourceComponentsProps, useTranslate, useList } from '@refinedev/core'
import { Create, useForm, } from '@refinedev/antd'
import { IGateway, IOrder, } from '../../interfaces'
import { Form, InputNumber, Select } from 'antd'
import { gatewayOptions } from 'components/GatewayOptions'

export const OrderCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps, form } = useForm<IOrder>({
    resource: 'trade_orders',
  })

  const kind = Form.useWatch('kind', form)

  const { data: gatewaysData } = useList<IGateway>({
    resource: 'gateways',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
      {
        field: 'direction',
        operator: 'eq',
        value: kind == 'payout' ? 'withdraw' : 'deposit',
      },
      {
        field: 'payment_type',
        operator: 'eq',
        value: ['payout', 'card', 'sbp', 'sbpqr', 'account', 'ecom', 'iban', 'qr', 'url', 'cvu', 'va', 'phone'],
      },
    ],
    pagination: {
      current: 1,
      pageSize: 1000,
    },
  })
  const gateways = gatewaysData?.data ?? []

  const kindOptions = [
    {
      label: t('trade_orders.kinds.income'),
      value: 'income',
    }, {
      label: t('trade_orders.kinds.payout'),
      value: 'payout',
    },
  ]

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          name="kind"
          label={t('trade_orders.fields.kind')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={kindOptions}
          // defaultValue={kindOptions[0]}
          />
        </Form.Item>
        <Form.Item
          name="amount"
          label={t('trade_orders.fields.amount')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            type="number"
            decimalSeparator="."
            precision={2}
            min={0}
          />
        </Form.Item>
        <Form.Item
          name="percent"
          label={t('trade_orders.fields.percent')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            type="number"
            decimalSeparator="."
            precision={2}
            min={0}
            max={100}
          />
        </Form.Item>
        <Form.Item
          name="gateway_id"
          label={t('trade_orders.fields.gateway')}
        >
          <Select
            allowClear
            showSearch
            options={gatewayOptions(gateways)}
            placeholder={t('agreements.form.gateway.placeholder')}
            filterOption={(input, option: any) =>
              (option?.desc ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
      </Form>
    </Create>
  )
}