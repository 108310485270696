import React from 'react'
import { useTable } from '@refinedev/antd'
import { IAgreement } from 'interfaces'
import AgreementsTable from '../agreements/list_table'
import AddAgreementButton from 'components/AddAgreementButton'

export const AgreementsList: React.FC<{ providerId: string }> = ({ providerId }) => {
  const { tableProps } = useTable<IAgreement>({
    resource: 'billing/agreements',
    filters: {
      permanent: [
        {
          field: 'provider_id',
          operator: 'eq',
          value: providerId,
        },
      ],
    },
  })

  return (
    <>
      <AddAgreementButton initialValues={{ type: 'provider', provider_id: providerId }} style={{ marginBottom: 16 }} />
      <AgreementsTable tableProps={tableProps}></AgreementsTable>
    </>
  )
}
