import { useInvalidate, useTranslate, useCan, useList } from '@refinedev/core'
import { useModalForm, } from '@refinedev/antd'
import { Select, Button, Modal, Form, Input, InputNumber, Checkbox, Flex, } from 'antd'
import { IGateway, IPaymentCascade, IMerchant } from 'interfaces'
import { gatewayOptions } from 'components/GatewayOptions'

const AddPaymentCascadeButton: React.FC<{
  merchant?: IMerchant
}> = ({
  merchant,
}) => {
    const t = useTranslate()
    const invalidate = useInvalidate()

    const { data: canCreatePaymentCascade } = useCan({
      resource: 'payment_cascades',
      action: 'create',
    })

    const {
      modalProps: createModalProps,
      formProps: createFormProps,
      show: showModal,
      form
    } = useModalForm<IPaymentCascade>({
      resource: 'payment_cascades',
      action: 'create',
      redirect: false,
      onMutationSuccess: () => {
        invalidate({
          resource: 'payment_cascades',
          invalidates: ['list'],
        })
      },
    })

    const kind = Form.useWatch('kind', form)

    const { data: gatewaysData } = useList<IGateway>({
      resource: 'gateways',
      filters: [
        {
          field: 'compact',
          operator: 'eq',
          value: true,
        },
        {
          field: 'currency',
          operator: 'eq',
          value: merchant?.currency,
        },
        {
          field: 'direction',
          operator: 'eq',
          value: kind == 'payout' ? 'withdraw' : 'deposit',
        },
        {
          field: 'payment_type',
          operator: 'eq',
          value: ['payout', 'card', 'sbp', 'sbpqr', 'account', 'ecom', 'iban', 'qr', 'url', 'cvu', 'va', 'phone'],
        },
      ],
      pagination: {
        current: 1,
        pageSize: 1000,
      },
    })
    const gateways = gatewaysData?.data ?? []

    return (
      <>
        {canCreatePaymentCascade?.can &&
          <Flex
            justify='end'
            style={{
              marginBottom: 15,
            }}
          >
            <Button
              type='primary'
              onClick={() => {
                showModal()
              }}
              style={{
                width: 'fit-content'
              }}
            >
              {t('components.add_payment_cascade.buttons.create')}
            </Button>
          </Flex>
        }
        <Modal
          {...createModalProps}
          title={t('components.add_payment_cascade.titles.create')}
        >
          <Form {...createFormProps} layout="vertical">
            <Form.Item
              hidden
              name="merchant_id"
              initialValue={merchant ? merchant.id : null}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="payment_system"
              rules={[{ required: true, message: '' },]}
            >
              <Select
                allowClear
                showSearch
                options={[
                  {
                    label: t('payment_cascades.payment_system.spacepayments'),
                    value: 'spacepayments',
                  },
                  {
                    label: t('payment_cascades.payment_system.auris'),
                    value: 'auris',
                  }
                ]}
                placeholder={t('payment_cascades.filter.payment_system.placeholder')}
                filterOption={(input, option: any) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item
              name="kind"
              rules={[{ required: true, message: '' },]}
            >
              <Select
                allowClear
                options={[
                  {
                    label: t('payment_cascades.kind.income'),
                    value: 'income',
                  },
                  {
                    label: t('payment_cascades.kind.payout'),
                    value: 'payout',
                  }
                ]}
                placeholder={t('payment_cascades.filter.kind.placeholder')}
              />
            </Form.Item>
            <Form.Item
              label={t('payment_cascades.form.traffic_percentage.label')}
              name="traffic_percentage"
              rules={[{ required: true, message: '' },]}
            >
              <InputNumber min={0} max={100} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="all_gateways"
              valuePropName="checked"
              label={t('payment_cascades.fields.all_gateways')}
            >
              <Checkbox />
            </Form.Item>
            <Form.Item
              name="gateway_id"
              label={t('payment_cascades.fields.gateway')}
            >
              <Select
                disabled={!kind}
                allowClear
                showSearch
                options={gatewayOptions(gateways)}
                placeholder={t('agreements.form.gateway.placeholder')}
                filterOption={(input, option: any) =>
                  (option?.desc ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }

export default AddPaymentCascadeButton
