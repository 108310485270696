import { IResourceComponentsProps, useCan, useTranslate, useList } from '@refinedev/core'
import { Edit, useForm } from '@refinedev/antd'
import { Checkbox, Form, Input, InputNumber, Select } from 'antd'
import { timeZoneOptions } from 'utils'
import { IMerchant, ICurrency } from 'interfaces'

export const MerchantEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<IMerchant>({
    action: 'edit',
    redirect: 'show',
  })

  const { data: canChangeInterval } = useCan({
    resource: 'merchants',
    action: 'change_interval',
  })

  const { data: canChangePayoutsSla } = useCan({
    resource: 'merchants',
    action: 'change_payouts_sla',
  })

  const { data: canChangeAutoApproveLimit } = useCan({
    resource: 'merchants',
    action: 'change_auto_approve_limit',
  })

  const { data: canChangeAntifraudEnabled } = useCan({
    resource: 'merchants',
    action: 'change_antifraud_enabled',
  })

  const { data: canChangeAurisShopId } = useCan({
    resource: 'merchants',
    action: 'change_auris_shop_id',
  })

  const { data: canChangeDisputesEnabled } = useCan({
    resource: 'merchants',
    action: 'change_disputes_enabled',
  })

  const { data: currenciesData } = useList<ICurrency>({
    resource: 'currencies',
    pagination: {
      current: 1,
      pageSize: 1000,
    },
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
  })
  const currencies = currenciesData?.data ?? []

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item label={t('merchants.fields.name')} name="name">
          <Input />
        </Form.Item>
        <Form.Item label={t('merchants.fields.webhook_url')} name="webhook_url">
          <Input />
        </Form.Item>
        <Form.Item label={t('merchants.fields.webhook_secret')} name="webhook_secret">
          <Input />
        </Form.Item>
        <Form.Item
          label={t('merchants.fields.success_redirect_url')}
          name="success_redirect_url"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('merchants.fields.failure_redirect_url')}
          name="failure_redirect_url"
        >
          <Input />
        </Form.Item>
        {canChangeInterval?.can && (
          <Form.Item
            label={t('merchants.fields.interval_in_minutes')}
            name="interval_in_minutes"
          >
            <InputNumber placeholder={t('merchants.fields.interval_in_minutes')} min={1} max={100} />
          </Form.Item>
        )}
        {canChangeInterval?.can && (
          <Form.Item
            label={t('merchants.fields.after_ecom_timeout_in_minutes')}
            name="after_ecom_timeout_in_minutes"
          >
            <InputNumber placeholder={t('merchants.fields.after_ecom_timeout_in_minutes')} min={1} max={100} />
          </Form.Item>
        )}
        {canChangeInterval?.can && (
          <Form.Item
            label={t('merchants.fields.fill_from_timeout_in_minutes')}
            name="fill_from_timeout_in_minutes"
          >
            <InputNumber placeholder={t('merchants.fields.fill_from_timeout_in_minutes')} min={1} max={100} />
          </Form.Item>
        )}
        {canChangeInterval?.can && (
          <Form.Item
            label={t('merchants.fields.confirm_ecom_timeout_in_minutes')}
            name="confirm_ecom_timeout_in_minutes"
          >
            <InputNumber placeholder={t('merchants.fields.confirm_ecom_timeout_in_minutes')} min={1} max={100} />
          </Form.Item>


        )}
        {canChangePayoutsSla?.can && (
          <Form.Item
            label={t('merchants.fields.payouts_sla_in_minutes')}
            name="payouts_sla_in_minutes"
          >
            <Input />
          </Form.Item>
        )}
        {canChangeAutoApproveLimit?.can && (
          <Form.Item
            label={t('merchants.fields.auto_approve_limit')}
            name="auto_approve_limit"
          >
            <Input />
          </Form.Item>
        )}
        {canChangeAntifraudEnabled?.can && (
          <Form.Item
            label={t('merchants.fields.antifraud_enabled')}
            valuePropName="checked"
            name="antifraud_enabled"
          >
            <Checkbox />
          </Form.Item>
        )}
        {canChangeAurisShopId?.can && (
          <Form.Item
            label={t('merchants.fields.auris_shop_id')}
            name="auris_shop_id"
          >
            <Input />
          </Form.Item>
        )}
        {canChangeDisputesEnabled?.can && (
          <Form.Item
            label={t('merchants.fields.disputes_enabled')}
            valuePropName="checked"
            name="disputes_enabled"
          >
            <Checkbox />
          </Form.Item>
        )}
        <Form.Item name="timezone" label={t('merchants.fields.timezone')}>
          <Select
            showSearch
            //@ts-ignore
            options={timeZoneOptions}
          />
        </Form.Item>
        <Form.Item name="currency" label={t('merchants.fields.currency')}>
          <Select
            disabled
            options={currencies.map((currency) => ({
              label: currency.code,
              value: currency.code,
            }))}
          />
        </Form.Item>
        <Form.Item
          label={t('merchants.fields.telegram_chat_id')}
          name="telegram_chat_id"
          help={t('merchants.form.telegram_chat_id.help')}
        >
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  )
}
