import { MoreOutlined } from '@ant-design/icons'

import { Button, Modal, Typography, Spin } from 'antd'

import { useInvalidate, useShow } from '@refinedev/core'

import { IOperation } from 'interfaces'
import { OperationStatus } from 'pages/cashin/OperationStatus'
import { useState } from 'react'
import { formattedCurrencyAmount } from 'utils'

import { OperationControls } from './OperationControls'

const { Text } = Typography

export const OperationActions: React.FC<{ id: string }> = ({ id }) => {
  const { queryResult, setShowId } = useShow<IOperation>({ resource: 'operations' })
  const { data, isLoading } = queryResult
  const record = data?.data

  const invalidate = useInvalidate()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setShowId(id)
    invalidate({
      resource: 'operations',
      invalidates: ['detail'],
      id,
    })
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const ModalTitle: React.FC = () => {
    const operationKind = record?.kind === 'outcome' ? 'Вывод' : 'Пополнение'

    return (
      <div
        style={{
          marginRight: 20,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Text>
          {operationKind} на сумму{' '}
          {record ? formattedCurrencyAmount(record.amount) : null}
        </Text>
        {record && <OperationStatus status={record.status} />}
      </div>
    )
  }

  return (
    <>
      <Button icon={<MoreOutlined />} size="small" onClick={showModal} />
      <Modal
        open={isModalOpen}
        title={<ModalTitle />}
        onCancel={closeModal}
        footer={null}
        width={800}
      >
        {isLoading && (
          <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <Spin tip="Loading" size="large" />
          </div>
        )}
        {isModalOpen && !isLoading && record && <OperationControls record={record} />}
      </Modal>
    </>
  )
}
