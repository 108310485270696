import {
  CrudFilters,
  getDefaultFilter,
  IResourceComponentsProps,
  usePermissions,
  useTranslate,
} from '@refinedev/core'

import { List, ShowButton, TextField, useTable } from '@refinedev/antd'
import { Form, Input, Space, Table, TableProps } from 'antd'
import { IScoringClient } from 'interfaces'
import { formattedCurrencyAmount } from 'utils'
import { SearchOutlined } from '@ant-design/icons'
import { ShortId } from 'components/ShortId'

const ScoringCliensTable: React.FC<
  IResourceComponentsProps & { tableProps: TableProps<IScoringClient> }
> = ({ tableProps }) => {
  const t = useTranslate()

  return (
    <Table
      {...tableProps}
      rowKey="id"
      pagination={{
        ...tableProps.pagination,
        pageSize: 10,
        position: ['bottomLeft'],
        size: 'small',
      }}
    >
      <Table.Column<IScoringClient>
        dataIndex="id"
        key="id"
        title={t('scoring/clients.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IScoringClient>
        dataIndex={['merchant', 'name']}
        key="merchant"
        title={t('scoring/clients.fields.merchant')}
      />
      <Table.Column<IScoringClient>
        dataIndex="client_id"
        key="client_id"
        title={t('scoring/clients.fields.client_id')}
        render={(value) => <TextField value={value} />}
      />
      <Table.Column<IScoringClient>
        dataIndex="color"
        key="color"
        title={t('scoring/clients.fields.color')}
        render={(value) => <TextField value={value} />}
      />
      <Table.Column<IScoringClient>
        dataIndex="sum_approved_amount"
        key="sum_approved_amount"
        title={t('scoring/clients.fields.sum_approved_amount')}
        render={(value, record) => (
          <TextField value={formattedCurrencyAmount(value, 0, record.currency?.code)} />
        )}
      />
      <Table.Column<IScoringClient>
        dataIndex="sum_approved_count"
        key="sum_approved_count"
        title={t('scoring/clients.fields.sum_approved_count')}
        render={(value) => <TextField value={value} />}
      />
      <Table.Column<IScoringClient>
        dataIndex="sum_all_count"
        key="sum_all_count"
        title={t('scoring/clients.fields.sum_all_count')}
        render={(value) => <TextField value={value} />}
      />
      <Table.Column<IScoringClient>
        title="Actions"
        dataIndex="actions"
        render={(_, record) => (
          <Space>
            <ShowButton size="small" recordItemId={record.id} />
          </Space>
        )}
      />
    </Table>
  )
}

export default ScoringCliensTable
