import {
  CrudFilters,
  getDefaultFilter,
  IResourceComponentsProps,
  useTranslate,
  useList
} from '@refinedev/core'

import { List, useTable, } from '@refinedev/antd'
import AccountsTable from './list_table'
import { IAccount, ICurrency } from 'interfaces'
import { Form, Input, Flex, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

export const AccountsList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { tableProps, searchFormProps, filters } = useTable<IAccount>({
    resource: 'accounts',

    onSearch: (params: any) => {
      const { id, merchant_id, agent_id, team_id, payment_provider_id, currency_id } = params
      const filters: CrudFilters = []

      filters.push({
        field: 'id',
        operator: 'eq',
        value: id,
      })

      filters.push({
        field: 'currency_id',
        operator: 'eq',
        value: currency_id,
      })

      filters.push({
        field: 'merchant_id',
        operator: 'eq',
        value: merchant_id,
      })

      filters.push({
        field: 'agent_id',
        operator: 'eq',
        value: agent_id,
      })

      filters.push({
        field: 'team_id',
        operator: 'eq',
        value: team_id,
      })

      filters.push({
        field: 'payment_provider_id',
        operator: 'eq',
        value: payment_provider_id,
      })

      return filters
    },

    pagination: {
      pageSize: 20,
    },
  })

  const { data: currenciesData } = useList<ICurrency>({
    resource: 'currencies',
    pagination: {
      current: 1,
      pageSize: 1000,
    },
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
  })
  const currencies = currenciesData?.data ?? []

  var timerId: any
  const debounceFunction = (func: any, delay: number) => {
    clearTimeout(timerId)
    timerId = setTimeout(func, delay)
  }
  const onValueChange = () => {
    debounceFunction(() => {
      searchFormProps.form?.submit()
    }, 400)
  }

  return (
    <List>
      <Form
        {...searchFormProps}
        layout="vertical"
        size={'middle'}
        onValuesChange={onValueChange}
        initialValues={{
          id: getDefaultFilter('id', filters),
          currency_id: getDefaultFilter('currency_id', filters),
          merchant_id: getDefaultFilter('merchant_id', filters),
          agent_id: getDefaultFilter('agent_id', filters),
          team_id: getDefaultFilter('team_id', filters),
          payment_provider_id: getDefaultFilter('payment_provider_id', filters),
        }}
      >
        <Flex
          gap={5}
          wrap
        >
          <Form.Item
            name="id"
            style={{
              flexGrow: 1,
            }}
          >
            <Input
              placeholder={t('accounts.filter.id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="currency_id"
            style={{
              flexGrow: 1,
            }}
          >
            <Select
              options={currencies.map((currency) => ({
                label: currency.code,
                value: currency.id,
              }))}
              allowClear
              showSearch
              placeholder={t('accounts.filter.currency.placeholder')}
            />
          </Form.Item>
        </Flex>
        <Flex
          gap={5}
          wrap
        >
          <Form.Item
            name="merchant_id"
            style={{
              flexGrow: 1,
            }}
          >
            <Input
              placeholder={t('accounts.filter.merchant_id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="team_id"
            style={{
              flexGrow: 1,
            }}
          >
            <Input
              placeholder={t('accounts.filter.team_id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="agent_id"
            style={{
              flexGrow: 1,
            }}
          >
            <Input
              placeholder={t('accounts.filter.agent_id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="payment_provider_id"
            style={{
              flexGrow: 1,
            }}
          >
            <Input
              placeholder={t('accounts.filter.payment_provider_id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
        </Flex>
      </Form>
      <AccountsTable tableProps={tableProps} />
    </List>
  )
}
