import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Table, TableProps, Grid, Space, Tag } from 'antd'
import { TagField, } from '@refinedev/antd'
import { IProviderGateway } from 'interfaces'
import DateField from 'components/DateField'
import { ShortId } from 'components/ShortId'
import { ShowButton } from '@refinedev/antd'
import { GatewayType } from 'components/GatewayType'
import { GatewayDirection } from 'components/GatewayDirection'
import { GatewayMethod } from 'components/GatewayMethod'

const ProviderGatewaysTable: React.FC<
  IResourceComponentsProps & {
    tableProps: TableProps<IProviderGateway>
  }
> = ({ tableProps }) => {
  const t = useTranslate()

  const breakpoint = Grid.useBreakpoint();
  const isMobile = typeof breakpoint.lg === "undefined" ? true : !breakpoint.lg;

  return (
    <Table
      {...tableProps}
      size="small"
      rowKey="id"
      pagination={{
        ...tableProps.pagination,
        position: ['bottomLeft'],
        size: 'small',
      }}
    >
      <Table.Column<IProviderGateway>
        dataIndex='provider'
        ellipsis
        key="provider"
        title={t('provider_gateways.fields.provider')}
        render={(provider) => (
          <Space>
            <Tag style={{ fontSize: 14, }}>{provider?.name}</Tag>
            <ShortId value={provider.id} />
          </Space>
        )}
      />
      <Table.Column<IProviderGateway>
        dataIndex="name"
        ellipsis
        key="name"
        title={t('provider_gateways.fields.name')}
      />
      <Table.Column<IProviderGateway>
        dataIndex="external_method"
        ellipsis
        key="external_method"
        title={t('provider_gateways.fields.external_method')}
      />
      <Table.Column
        ellipsis
        dataIndex='gateway'
        key="gateway"
        title={t('provider_gateways.fields.gateway')}
        render={(gateway) => (
          <Space>
            <GatewayDirection direction={gateway.direction} />
            <GatewayType type={gateway.payment_type} />
            {gateway.currency}
            {gateway.name}
            <GatewayMethod method={gateway.payment_method} />
            <ShortId value={gateway.id} />
          </Space>
        )}
      />
      <Table.Column<IProviderGateway>
        dataIndex="status"
        ellipsis
        key="status"
        title={t('provider_gateways.fields.status')}
        render={(value) => <TagField color={value === 'active' ? 'green' : 'red'} value={value} />}
      />
      <Table.Column<IProviderGateway>
        width={150}
        dataIndex="id"
        ellipsis
        key="id"
        title={t('provider_gateways.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IProviderGateway>
        ellipsis
        dataIndex="created_at"
        key="created_at"
        title={t('provider_gateways.fields.created_at')}
        render={(value) => <DateField style={{ fontSize: 12 }} value={value} />}
      />
      <Table.Column<IProviderGateway>
        ellipsis
        dataIndex="updated_at"
        key="updated_at"
        title={t('provider_gateways.fields.updated_at')}
        render={(value) => <DateField style={{ fontSize: 12 }} value={value} />}
      />
      <Table.Column<IProviderGateway>
        fixed="right"
        align="center"
        dataIndex="actions"
        key="actions"
        render={(_, record) =>
          <ShowButton
            hideText={isMobile}
            size="small"
            recordItemId={record.id}
          />
        }
      />
    </Table>
  )
}

export default ProviderGatewaysTable
