import React from 'react'
import { IResourceComponentsProps } from '@refinedev/core'
import { List, useTable } from '@refinedev/antd'
import { ICurrency } from 'interfaces'
import CurrenciesTable from './list_table'

export const CurrenciesList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<ICurrency>({
    pagination: {
      pageSize: 30,
    },
  })

  return (
    <List>
      <CurrenciesTable tableProps={tableProps}></CurrenciesTable>
    </List>
  )
}
