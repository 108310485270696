import React, { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { IShift } from 'interfaces'
import { Controls } from './controls'
import { PayoutControl } from './payout_control'
import { PayinControl } from './payin_control'
import { Stats } from './stats'
import { Timer } from './timer'
import { Flex, Typography, Grid } from 'antd'

const { Text } = Typography

export const Current: React.FC<{ shift: IShift }> = ({ shift }) => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = typeof breakpoint.lg === "undefined" ? true : !breakpoint.lg;

  const queryClient = useQueryClient()

  useEffect(() => {
    const timer = setInterval(() => {
      shift &&
        shift.status === 'started' &&
        queryClient.invalidateQueries(['shifts', 'current'])
    }, 30000)
    return () => clearInterval(timer)
  }, [shift, queryClient])

  return (
    <Flex
      vertical
    >
      <Stats shift={shift} />
      <Flex
        vertical={isMobile}
        align={isMobile ? 'start' : 'center'}
        gap={isMobile ? 10 : 40}
      >
        <Flex
          justify='space-between'
          align='center'
        >
          <Timer shift={shift} />
          <Controls shift={shift} />
        </Flex>
        <PayinControl shift={shift} style={{}} />
        <PayoutControl shift={shift} />
      </Flex>
    </Flex>
  )
}
