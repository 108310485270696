import { PaymentCascades } from 'components/PaymentCascades'
import { List, } from '@refinedev/antd'

export const PaymentCascadesList: React.FC = () => {
  return (
    <List
      resource='payment_cascades'
      canCreate
      breadcrumb={false}
    >
      <PaymentCascades />
    </List>
  )
}
