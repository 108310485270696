import {
  IResourceComponentsProps,
  useTranslate,
} from '@refinedev/core'
import { useForm, Edit } from '@refinedev/antd'
import {
  Form,
  Input,
  Select,
  Tabs,
  TabsProps,
  Typography,
} from 'antd'
import { IUser } from 'interfaces'
import { useEffect, useState } from 'react'
import { timeZoneOptions } from 'utils'

import './styles.css'
import { Security } from './security'
import { useSearchParams } from 'react-router-dom'

export const ProfileShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const [currentTab, setCurrentTab] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (currentTab === '') return

    setSearchParams((prevValue) => {
      prevValue.delete('tab')

      const newParams: { [key: string]: string } = {}

      newParams['tab'] = currentTab

      return newParams
    })
  }, [setSearchParams, currentTab])

  const { formProps, saveButtonProps } = useForm<IUser>({
    action: 'edit',
    resource: 'users',
    id: 'current',
    redirect: false,
  })

  const onTabChange = (key: string) => {
    setCurrentTab(key)
  }

  let tabsItems: TabsProps['items'] = []

  tabsItems.push({
    key: '1',
    label: t('profile.titles.common'),
    children: (
      <Edit
        saveButtonProps={saveButtonProps}
        headerButtons={<></>}
        title={<></>}
        wrapperProps={{ className: 'sexy' }}
      >
        <Form layout="vertical" {...formProps}>
          <Form.Item name="email" label={t('profile.fields.email')}>
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label={t('profile.fields.password')}
            help={t('hints.password')}
          >
            <Input />
          </Form.Item>
          <Form.Item name="timezone" label={t('profile.fields.timezone')}>
            <Select
              showSearch
              //@ts-ignore
              options={timeZoneOptions}
            />
          </Form.Item>
        </Form>
      </Edit>
    ),
  })
  tabsItems.push({
    key: '2',
    label: t('profile.titles.security'),
    children: <Security />,
  })

  return (
    <>
      <Typography.Title level={3}>{t('profile.titles.edit')}</Typography.Title>
      <Tabs
        defaultActiveKey={searchParams.get('tab') || '1'}
        items={tabsItems}
        onChange={onTabChange}
      />
    </>
  )
}
