import { IResourceComponentsProps, useTranslate, useList } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form, Input, Select, Checkbox } from "antd";

import { ITeam, ICurrency } from 'interfaces'

export const TeamCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<ITeam>()

  const { data: currenciesData } = useList<ICurrency>({
    resource: 'currencies',
    pagination: {
      current: 1,
      pageSize: 1000,
    },
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
  })
  const currencies = currenciesData?.data ?? []

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t('teams.fields.name')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="name" />
        </Form.Item>
        <Form.Item
          label={t('partners.fields.currency')}
          name="currency"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            options={currencies.map((currency) => ({
              label: currency.code,
              value: currency.code,
            }))}
          />
        </Form.Item>
        <Form.Item label={t('teams.fields.telegram_chat_id')} name="telegram_chat_id">
          <Input />
        </Form.Item>
        <Form.Item
          label={t('teams.fields.trade_orders_enabled')}
          name="trade_orders_enabled"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Create>
  )
}
