import {
  IResourceComponentsProps,
  useTranslate,
  useMany,
  GetManyResponse,
  useCan,
  CrudFilters,
  getDefaultFilter,
} from '@refinedev/core'

import { ShowButton, List, useTable, TextField, } from '@refinedev/antd'
import { Table, Space, Typography, Skeleton, Form, Input, Grid, } from 'antd'
import { IPartner, ITeam } from 'interfaces'
import AddTeamPartnerButton from 'components/AddTeamPartnerButton'
import { ShortId } from 'components/ShortId'
import BooleanTag from 'components/BooleanTag'
import {
  formattedAmount,
  formattedCurrencyAmount
} from 'utils'
import { SearchOutlined } from '@ant-design/icons'

const { Text } = Typography

export const Partner: React.FC<{
  id: string
  partnersData: GetManyResponse<IPartner> | undefined
}> = ({ id, partnersData }) => {
  const partner = partnersData && partnersData.data.find((item) => id === item.id)

  return (
    <Text code ellipsis style={{ display: 'flex', alignItems: 'center' }}>
      {partner?.name}
    </Text>
  )
}

export const TeamsList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const breakpoint = Grid.useBreakpoint();
  const isMobile = typeof breakpoint.lg === "undefined" ? true : !breakpoint.lg;

  const { tableProps, searchFormProps, filters } = useTable<ITeam>({
    onSearch: (params: any) => {
      const filters: CrudFilters = []
      const { search } = params

      filters.push({
        field: 'search',
        operator: 'eq',
        value: search,
      })

      return filters
    },
    pagination: {
      pageSize: 20,
    },
  })

  const { data: canCreatePartner } = useCan({
    resource: 'partners',
    action: 'create',
  })

  const { data: canAddPartner } = useCan({
    resource: 'partners',
    action: 'add_partner',
  })

  const { data: canListAccounts } = useCan({
    resource: 'accounts',
    action: 'list',
  })

  const partnersIds =
    tableProps?.dataSource?.flatMap((item: ITeam) =>
      item.partner_ids?.concat(item.partner_ids)
    ) ?? []

  const { data: partnersData, isLoading: partnersLoading } = useMany<IPartner>({
    resource: 'partners',
    ids: partnersIds,
    meta: {
      filters: [
        {
          field: 'compact',
          operator: 'eq',
          value: true,
        },
      ],
    },
    queryOptions: {
      enabled: !!(partnersIds.length > 0),
    },
  })

  var timerId: any

  const debounceFunction = (func: any, delay: number) => {
    clearTimeout(timerId)
    timerId = setTimeout(func, delay)
  }

  const onValueChange = () => {
    debounceFunction(() => {
      searchFormProps.form?.submit()
    }, 400)
  }

  return (
    <List canCreate={canCreatePartner?.can}>
      <Form
        {...searchFormProps}
        layout="vertical"
        size={'middle'}
        onValuesChange={onValueChange}
        initialValues={{
          search: getDefaultFilter('search', filters),
        }}
      >
        <Form.Item name="search">
          <Input
            placeholder={t('teams.filter.search.placeholder')}
            prefix={<SearchOutlined />}
            allowClear
          />
        </Form.Item>
      </Form>
      <Table
        scroll={{ x: '100%' }}
        {...tableProps}
        size="small"
        rowKey="id"
        pagination={
          tableProps.pagination && (tableProps.pagination?.total ?? 0) > 20
            ? {
              ...tableProps.pagination,
              pageSize: 20,
              position: ['bottomLeft'],
              size: 'small',
            }
            : false
        }
      >
        <Table.Column<ITeam>
          ellipsis
          dataIndex="id"
          key="id"
          title={t('teams.fields.id')}
          render={(value) => <ShortId value={value} />}
        />
        <Table.Column<ITeam>
          dataIndex="name"
          key="name"
          title={t('teams.fields.name')}
        />
        <Table.Column<ITeam>
          ellipsis
          dataIndex="currency"
          key="currency"
          title={t('teams.fields.currency')}
        />
        <Table.Column<ITeam>
          dataIndex="partner_ids"
          key="partner_ids"
          title={t('teams.fields.partners')}
          render={(value: string[], record: ITeam) => {
            if (partnersIds.length && partnersLoading) {
              return <Skeleton.Input active />
            }

            return (
              <Space wrap>
                {value.map((partner_id) => (
                  <Partner key={partner_id} id={partner_id} partnersData={partnersData} />
                ))}
                {canAddPartner?.can && (
                  <AddTeamPartnerButton id={record.id} />
                )}
              </Space>
            )
          }}
        />
        <Table.Column<ITeam>
          ellipsis
          dataIndex="deposit_balance"
          key="deposit_balance"
          title={t('teams.fields.deposit_balance')}
          render={(value) => (value ? formattedAmount(value) : t('partners.titles.no_deposit'))}
        />
        {canListAccounts?.can &&
          <>
            <Table.Column<ITeam>
              ellipsis
              dataIndex="deposit_balance_v2"
              key="deposit_balance_v2"
              title={t('teams.fields.deposit_balance') + ' V2'}
              render={(value, record) => (
                <TextField value={formattedCurrencyAmount(value, 2, record.currency)} />
              )}
            />
            <Table.Column<ITeam>
              ellipsis
              dataIndex="hold_balance"
              key="hold_balance"
              title={t('accounts.fields.hold_balance')}
              render={(value, record) => (
                <TextField value={formattedCurrencyAmount(value, 2, record.currency)} />
              )}
            />
            <Table.Column<ITeam>
              ellipsis
              dataIndex="balance_with_holds"
              key="balance_with_holds"
              title={t('accounts.fields.balance_with_holds')}
              render={(value, record) => (
                <TextField value={formattedCurrencyAmount(value, 2, record.currency)} />
              )}
            />
          </>
        }

        <Table.Column<ITeam>
          dataIndex="telegram_chat_id"
          key="telegram_chat_id"
          title={t('teams.fields.telegram_chat_id')}
        />
        <Table.Column<ITeam>
          dataIndex="trade_orders_enabled"
          key="trade_orders_enabled"
          title={t('teams.fields.trade_orders_enabled')}
          render={(value) => <BooleanTag value={value} />}
        />
        <Table.Column<ITeam>
          fixed="right"
          align="center"
          dataIndex="actions"
          key="actions"
          render={(_, record) =>
            <ShowButton
              hideText={isMobile}
              size="small"
              recordItemId={record.id}
            />
          }
        />
      </Table>
    </List>
  )
}
