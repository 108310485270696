import { IResourceComponentsProps, useTranslate, } from '@refinedev/core'
import { Create, useForm, } from '@refinedev/antd'
import { Form, Input, } from 'antd'
import { ICurrency } from 'interfaces'

export const CurrencyCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps, } = useForm<ICurrency>()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
      >
        <Form.Item
          label={t('currencies.fields.code')}
          name="code"
          rules={[{ required: true, },]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  )
}
