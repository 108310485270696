import { IResourceComponentsProps, useTranslate, useCan } from '@refinedev/core'
import { Table, TableProps, Tag, Space, Descriptions, Grid, Flex } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useState } from 'react'
import { IAccountOperation, IAccountEntry } from 'interfaces'
import { ShowButton } from '@refinedev/antd'
import { formattedCurrencyAmount } from 'utils'
import { ShortId } from 'components/ShortId'
import { TextField, useTable } from '@refinedev/antd'
import DateField from 'components/DateField'
import { AccountOperationStatus } from 'components/AccountOperationStatus'
import { AccountOperationKind } from 'components/AccountOperationKind'
import { AccountOperationEventsTable } from 'components/AccountOperationEventsTable'
import { AccountEntriesTable } from 'components/AccountEntriesTable'

const AccountEntries: React.FC<{ account_operation: IAccountOperation }> = ({ account_operation }) => {
  const t = useTranslate()

  const { tableProps } = useTable<IAccountEntry>({
    resource: 'account_entries',
    filters: {
      permanent: [
        {
          field: 'account_operation_id',
          operator: 'eq',
          value: account_operation.id,
        },
      ]
    },
    pagination: {
      pageSize: 20,
    },
  })

  return (
    <AccountEntriesTable tableProps={tableProps} />
  )
}

const AccountOperationsTable: React.FC<
  IResourceComponentsProps & { tableProps: TableProps<IAccountOperation> }
> = ({ tableProps }) => {
  const t = useTranslate()

  const breakpoint = Grid.useBreakpoint();
  const isMobile = typeof breakpoint.lg === "undefined" ? true : !breakpoint.lg;

  const { data: canListMerchants } = useCan({
    resource: 'merchants',
    action: 'list',
  })
  const { data: canListAgents } = useCan({
    resource: 'agents',
    action: 'list',
  })
  const { data: canListTeams } = useCan({
    resource: 'teams',
    action: 'list',
  })
  const { data: canListAccounts } = useCan({
    resource: 'accounts',
    action: 'list',
  })
  const { data: canListProviders } = useCan({
    resource: 'providers',
    action: 'list',
  })
  const { data: canShowAccountOperations } = useCan({
    resource: 'account_operations',
    action: 'show',
  })

  const [expandedKey, setExpandedKey] = useState(String)
  const onExpand = (_: any, record: IAccountOperation) => {
    expandedKey === record.id ? setExpandedKey('') : setExpandedKey(record.id)
  }

  return (
    <Table
      {...tableProps}
      size="small"
      rowKey="id"
      expandable={{
        expandedRowRender: (account_operation) => (
          <Flex
            vertical
            gap={15}
          >
            <Descriptions
              layout='vertical'
              column={1}
              bordered
            >
              <Descriptions.Item label={t('account_operations.fields.comment')}>
                <TextArea contentEditable={false} rows={10} value={account_operation?.comment} />
              </Descriptions.Item>
            </Descriptions>
            <AccountOperationEventsTable account_operation_events={account_operation?.account_operation_events} />
            <AccountEntries account_operation={account_operation} />
          </Flex>
        ),
        onExpand,
        expandedRowKeys: [expandedKey]
      }}
      pagination={
        tableProps.pagination && (tableProps.pagination?.total ?? 0) > 10
          ? {
            ...tableProps.pagination,
            pageSize: 20,
            position: ['bottomLeft'],
            size: 'small',
          }
          : false
      }
    >
      <Table.Column<IAccountOperation>
        ellipsis
        dataIndex="id"
        key="id"
        title={t('account_operations.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IAccountOperation>
        ellipsis
        dataIndex={['account', 'currency', 'code']}
        key="currency_code"
        title={t('account_operations.fields.currency')}
      />
      <Table.Column<IAccountOperation>
        ellipsis
        dataIndex={'kind'}
        key="kind"
        title={t('account_operations.fields.kind')}
        render={(value) => <AccountOperationKind kind={value} />}
      />
      <Table.Column<IAccountOperation>
        ellipsis
        dataIndex="amount"
        key="amount"
        width={150}
        title={t('account_operations.fields.amount')}
        render={(value, record) => (
          <TextField value={formattedCurrencyAmount(value, 2, record?.account?.currency?.code)} />
        )}
      />
      <Table.Column<IAccountOperation>
        ellipsis
        dataIndex={'status'}
        key="status"
        title={t('account_operations.fields.status')}
        render={(value) => <AccountOperationStatus status={value} />}
      />
      {canListAccounts?.can && <Table.Column<IAccountOperation>
        ellipsis
        dataIndex="account"
        key="account"
        title={t('account_operations.fields.account')}
        render={(_, record) =>
          <Space direction='vertical' size={1}>
            <Tag><ShortId value={record?.account?.id} /></Tag>
            <Tag>{record?.account?.kind}</Tag>
          </Space>
        }
      />}
      <Table.Column<IAccountOperation>
        key="member"
        title={t('account_operations.fields.member')}
        render={(_, record) => (
          <Space direction='vertical' size={1}>
            {canListMerchants?.can && record?.merchant?.id && (
              <>
                {t('account_operations.fields.merchant')}
                <Tag><ShortId value={record?.merchant?.id} /></Tag>
                <Tag color='purple'>{record?.merchant?.name}</Tag>
              </>
            )}
            {canListTeams?.can && record?.team?.id && (
              <>
                {t('account_operations.fields.team')}
                <Tag><ShortId value={record?.team?.id} /></Tag>
                <Tag color='geekblue'>{record?.team?.name}</Tag>
              </>
            )}
            {canListProviders?.can && record?.provider?.id && (
              <>
                {t('account_operations.fields.provider')}
                <Tag><ShortId value={record?.provider?.id} /></Tag>
                <Tag color='blue'>{record?.provider?.name}</Tag>
              </>
            )}
            {canListAgents?.can && record?.agent?.id && (
              <>
                {t('account_operations.fields.agent')}
                <Tag><ShortId value={record?.agent?.id} /></Tag>
                <Tag>{record?.agent?.name}</Tag>
              </>
            )}
          </Space>
        )}
      />
      <Table.Column<IAccountOperation>
        ellipsis
        width={10}
        key="datetimes"
        title={t('account_operations.fields.datetimes')}
        render={(_, record) => (
          <Space
            direction='vertical'
            size={1}
            style={{ fontSize: 12 }}
          >
            <Space>
              {t('account_operations.fields.created_at')}
              <DateField
                value={record?.created_at}
                style={{ fontSize: 12 }}
              />
            </Space>
            <Space>
              {t('account_operations.fields.approved_at')}
              <DateField
                value={record?.approved_at}
                style={{ fontSize: 12, color: 'green' }}
              />
            </Space>
          </Space>
        )}
      />
      {canShowAccountOperations?.can &&
        <Table.Column<IAccountOperation>
          fixed="right"
          align="center"
          key="actions"
          render={(_, record) =>
            <ShowButton
              resource='account_operations'
              hideText={isMobile}
              size="small"
              recordItemId={record.id}
            />
          }
        />
      }
    </Table>
  )
}

export default AccountOperationsTable
