import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons'
import { useCan, useSelect, useTranslate } from '@refinedev/core'
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Space,
  Typography,
} from 'antd'
import { IOperation, IPaymentRequisite } from 'interfaces'
import { useEffect, useState } from 'react'
import { formattedCardNumber, formattedAccountNumber, formattedPhone, formattedIbanNumber } from 'utils'
import { OperationEvents } from './OperationEvents'

const { TextArea } = Input
const { Text } = Typography

const ApprovedAmount: React.FC<{
  record: IOperation
  onOverrideApprovedAmount: (approved_amount: number) => void
}> = ({ record, onOverrideApprovedAmount }) => {
  const t = useTranslate()
  const [selectVisible, setSelectVisible] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [approvedAmount, setApprovedAmount] = useState<number | null>(null)

  const onButtonClick = () => {
    setIsSubmitting(true)
    approvedAmount && onOverrideApprovedAmount(approvedAmount)
    setApprovedAmount(null)
    setIsSubmitting(false)
    setSelectVisible(false)
  }

  const { data: canOverrideApprovedAmount } = useCan({
    resource: 'operations',
    action: 'override_approved_amount',
  })

  if (!['auto_approved', 'manually_approved'].includes(record.status)) return <></>

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space>
        <Text>Подтверждённая сумма: </Text>
        <Text>{record.approved_amount}</Text>
        {canOverrideApprovedAmount?.can && (
          <Button
            type="link"
            onClick={() => {
              setSelectVisible(!selectVisible)
            }}
          >
            Изменить сумму
          </Button>
        )}
      </Space>
      {selectVisible && (
        <>
          <Form.Item
            name="approved_amount"
            help={'Отправим вебхук после сохранения новой суммы'}
          >
            <InputNumber
              controls={false}
              min={1}
              style={{ width: '100%' }}
              value={approvedAmount}
              placeholder="Введи сумму, которую просит мерчант"
              onChange={(value) => setApprovedAmount(value)}
            />
          </Form.Item>
          <Button
            style={{ width: '100%', marginTop: '1em' }}
            disabled={!approvedAmount}
            onClick={onButtonClick}
            loading={isSubmitting}
          >
            {t('operations.actions.override_approved_amount')}
          </Button>
        </>
      )}
    </Space>
  )
}

const SelectPaymentRequisite: React.FC<{ onSubmit: any }> = ({ onSubmit }) => {
  const t = useTranslate()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [selectedPaymentRequisiteId, selectPaymentRequisiteId] = useState<
    undefined | string
  >(undefined)

  const { queryResult } = useSelect<IPaymentRequisite>({
    resource: 'payment_requisites',
    optionLabel: 'card_number',
    optionValue: 'id',
    filters: [
      {
        field: 'status',
        operator: 'eq',
        value: ['active', 'blocked', 'suspended', 'overflowed'],
      },
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })

  const { isFetching } = queryResult

  const groupedOptions = {}

  queryResult.data?.data.forEach((item) => {
    const status = item.status !== 'active' ? ` (${item.status}) ` : ''

    //@ts-ignore
    groupedOptions[item.partner.id] = groupedOptions[item.partner.id] || {
      label: item.partner.name,
      options: [],
    }
    //@ts-ignore
    groupedOptions[item.partner.id]['options'].push({
      label: `${formattedCardNumber(item.card_number)} [${formattedPhone(
        item.phone)}] ${formattedAccountNumber(item.account)} ${formattedIbanNumber(item.iban)} ${status}`,
      value: item.id,
    })
  })

  const paymentRequisiteOptions = Object.values(groupedOptions)

  const onButtonClick = () => {
    setIsSubmitting(true)
    onSubmit(selectedPaymentRequisiteId)
    selectPaymentRequisiteId(undefined)
    setIsSubmitting(false)
  }

  return (
    <>
      <Select
        dropdownStyle={{ fontFamily: 'monospace' }}
        style={{ width: '100%' }}
        // @ts-ignore
        options={paymentRequisiteOptions}
        allowClear
        showSearch
        loading={isFetching}
        onSelect={(value) => selectPaymentRequisiteId(value)}
        placeholder={t('operations.form.payment_requisite.placeholder')}
        filterOption={(input, option: any) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
      />
      <Button
        style={{ width: '100%', marginTop: '1em' }}
        disabled={!selectedPaymentRequisiteId}
        onClick={onButtonClick}
        loading={isSubmitting}
      >
        {t('operations.actions.update_payment_requisite')}
      </Button>
    </>
  )
}

const PartnerInfo: React.FC<{ record: IOperation }> = ({ record }) => {
  return record.partner?.name && <Text>{`Партнер: ${record.partner.name}`}</Text>
}

const EditablePaymentRequisite: React.FC<{
  record: IOperation
  onPaymentRequisiteChange: (payment_requisite_id: string) => void
}> = ({ record, onPaymentRequisiteChange }) => {
  const [selectVisible, setSelectVisible] = useState<boolean>(false)

  const onSubmit = (payment_requisite_id: string): void => {
    console.log('onsubmit', payment_requisite_id)
    onPaymentRequisiteChange(payment_requisite_id)
    setSelectVisible(false)
  }

  const { data: canUpdatePaymentRequisite } = useCan({
    resource: 'operations',
    action: 'update_payment_requisite',
  })

  return record.payment_requisite ? (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space>
        <Text>Реквизит: </Text>
        <Text>
          {record.payment_requisite?.card_number
            ? formattedCardNumber(record.payment_requisite?.card_number)
            : record.payment_requisite?.account
              ? formattedAccountNumber(record.payment_requisite?.account)
              : record.payment_requisite.sbp?.phone}
        </Text>
        {canUpdatePaymentRequisite?.can && (
          <Button
            type="link"
            onClick={() => {
              setSelectVisible(!selectVisible)
            }}
          >
            Сменить
          </Button>
        )}
      </Space>
      {selectVisible && <SelectPaymentRequisite onSubmit={onSubmit} />}
    </Space>
  ) : (
    <></>
  )
}

const ProposedAmount: React.FC<{
  record: IOperation
  onProposeAmount: (proposed_amount: number) => void
}> = ({ record, onProposeAmount }) => {
  const t = useTranslate()
  const [selectVisible, setSelectVisible] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [proposedAmount, setProposedAmount] = useState<number | null>(null)

  const onButtonClick = () => {
    setIsSubmitting(true)
    proposedAmount && onProposeAmount(proposedAmount)
    setProposedAmount(null)
    setIsSubmitting(false)
    setSelectVisible(false)
  }

  const { data: canProposeAmount } = useCan({
    resource: 'operations',
    action: 'propose_amount',
  })

  const proposedAmountInfo = record.proposed_amount ? (
    <>
      <Text style={{ textDecoration: 'line-through' }}>{record.amount}</Text>
      <Text>{record.proposed_amount}</Text>
    </>
  ) : (
    <Text>{record.amount}</Text>
  )

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space>
        <Text>Сумма перевода: </Text>
        {proposedAmountInfo}
        {canProposeAmount?.can &&
          !['auto_approved', 'manually_approved'].includes(record.status) && (
            <Button
              type="link"
              onClick={() => {
                setSelectVisible(!selectVisible)
              }}
            >
              Клиент перевел другую сумму
            </Button>
          )}
      </Space>
      {selectVisible && (
        <>
          <Form.Item
            name="proposed_amount"
            help={'Оператор сможет подтвердить заказ по СМС на эту сумму'}
          >
            <InputNumber
              controls={false}
              min={1}
              style={{ width: '100%' }}
              value={proposedAmount}
              placeholder="Введи фактическую сумму, которую перевёл клиент"
              onChange={(value) => setProposedAmount(value)}
            />
          </Form.Item>
          <Button
            style={{ width: '100%', marginTop: '1em' }}
            disabled={!proposedAmount}
            onClick={onButtonClick}
            loading={isSubmitting}
          >
            {t('operations.actions.propose_amount')}
          </Button>
        </>
      )}
    </Space>
  )
}

export const OperationForm: React.FC<{
  record: IOperation
  onComment: ({ comment }: { comment: string }) => void
  onApprove: ({
    comment,
    approvedAmount,
  }: {
    comment: string
    approvedAmount: number
  }) => void
  onReject: ({ comment }: { comment: string }) => void
  onRejectApproved: ({ comment }: { comment: string }) => void
  onPaymentRequisiteChange: (payment_requisite_id: string) => void
  onProposeAmount: (proposed_amount: number) => void
  onOverrideApprovedAmount: (approved_amount: number) => void
}> = ({
  onComment,
  record,
  onApprove,
  onReject,
  onRejectApproved,
  onPaymentRequisiteChange,
  onProposeAmount,
  onOverrideApprovedAmount,
}) => {
    const t = useTranslate()
    const [comment, setComment] = useState('')
    const [approvedAmount, setApprovedAmount] = useState<number>(0)
    const [approvedAmountVisible, setApprovedAmountVisible] = useState<boolean>(false)

    useEffect(() => {
      setApprovedAmount(Number(record.amount))
    }, [record])

    const approvedAmountNeeded = record?.kind !== 'outcome'

    const { data: canViewPartners } = useCan({
      resource: 'partners',
      action: 'filter',
    })

    return (
      <>
        {canViewPartners?.can && <PartnerInfo record={record} />}
        <EditablePaymentRequisite
          record={record}
          onPaymentRequisiteChange={onPaymentRequisiteChange}
        />
        <ProposedAmount record={record} onProposeAmount={onProposeAmount} />
        <ApprovedAmount
          record={record}
          onOverrideApprovedAmount={onOverrideApprovedAmount}
        />
        <Divider />
        <Form layout="vertical">
          <Form.Item label={t('operations.fields.comment')}>
            <TextArea
              rows={4}
              allowClear
              defaultValue={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Form.Item>
          {record?.available_actions?.includes('manually_approve') &&
            approvedAmountNeeded && (
              <>
                <Checkbox
                  style={{ marginBottom: 24 }}
                  onChange={(e) => {
                    setApprovedAmountVisible(e.target.checked)
                  }}
                >
                  {t('operations.fields.approved_amount_checkbox')}
                </Checkbox>
                {approvedAmountVisible && (
                  <Form.Item label={t('operations.fields.approved_amount')}>
                    <InputNumber
                      style={{ width: '100%' }}
                      min={1}
                      prefix="₽"
                      value={approvedAmount}
                      onChange={(value) => (value ? setApprovedAmount(value) : null)}
                    />
                  </Form.Item>
                )}
              </>
            )}
          <Space wrap>
            {record?.available_actions?.includes('manually_approve') && (
              <Button
                htmlType="submit"
                onClick={() => onApprove({ comment, approvedAmount })}
                type="primary"
                icon={<CheckCircleOutlined />}
              >
                {t('operations.actions.manually_approve')}
              </Button>
            )}
            {record?.available_actions?.includes('reject') && (
              <Button
                htmlType="submit"
                onClick={() => onReject({ comment })}
                type="default"
                danger={true}
                icon={<StopOutlined />}
              >
                {t('operations.actions.reject')}
              </Button>
            )}
            {record?.available_actions?.includes('reject_approved') && (
              <Popconfirm
                title={t('operations.confirm.reject_approved.title')}
                description={t('operations.confirm.reject_approved.description')}
                onConfirm={() => {
                  onRejectApproved({ comment })
                }}
                okText={t('operations.confirm.reject_approved.ok')}
                cancelText={t('operations.confirm.reject_approved.cancel')}
              >
                <Button
                  htmlType="submit"
                  type="default"
                  danger={true}
                  icon={<StopOutlined />}
                >
                  {t('operations.actions.reject_approved')}
                </Button>
              </Popconfirm>
            )}
            <Button
              htmlType="submit"
              onClick={() => onComment({ comment })}
              disabled={!comment.length}
            >
              {t('operations.actions.leave_comment')}
            </Button>
          </Space>
          {record?.available_actions?.includes('manually_approve') && (
            <Space style={{ marginTop: 16 }}>
              <Alert
                message={t('operations.actions.manually_approve_hint')}
                type="warning"
              />
            </Space>
          )}
        </Form>
        {record && <OperationEvents record={record} style={{ marginTop: 20 }} />}
      </>
    )
  }
