import React from 'react'
import { IResourceComponentsProps, useShow, useTranslate, useCan } from '@refinedev/core'
import { Show, EditButton } from '@refinedev/antd'
import { Descriptions, Space, Tabs, TabsProps, Typography, Flex, Tag, } from 'antd'
import { IProvider } from 'interfaces'
import { AgreementsList } from './agreements_list'
import { AccountOperationsList } from './AccountOperationsList'
import AddAccountOperationButton from 'components/AddAccountOperationButton'
import { formattedAmount } from 'utils'

const { Text } = Typography

export const ProviderShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { queryResult } = useShow<IProvider>()
  const { data, isLoading } = queryResult
  const record = data?.data

  const { data: canListAgreements } = useCan({
    resource: 'billing/agreements',
    action: 'list',
  })

  const { data: canListAccountOperations } = useCan({
    resource: 'account_operations',
    action: 'list',
  })

  const { data: canListAccounts } = useCan({
    resource: 'accounts',
    action: 'list',
  })

  const { data: canListOperations } = useCan({
    resource: 'operations',
    action: 'list',
  })

  let tabsItems: TabsProps['items'] = []
  canListAccountOperations?.can &&
    tabsItems.push({
      key: '1',
      label: t('providers.titles.account_operations'),
      children: record && !isLoading ? <AccountOperationsList providerId={record.id} /> : null,
    })
  canListAgreements?.can &&
    tabsItems.push({
      key: '2',
      label: t('providers.titles.agreements'),
      children: record && !isLoading ? <AgreementsList providerId={record.id} /> : null,
    })

  const headerButtons = () => {
    if (!record) {
      return
    }
    return (
      <>
        <EditButton
          resource="providers"
          recordItemId={record.id}
          title={t('buttons.edit')}
        />
      </>
    )
  }

  return (
    <>
      <Show
        isLoading={isLoading}
        title={record?.name || ''}
        headerButtons={headerButtons}
      >
        <Flex
          vertical
          gap={40}
        >
          <Descriptions
            column={1}
            bordered
            style={{
              maxWidth: 800,
            }}
          >
            <Descriptions.Item label={t('providers.fields.name')}>
              {record?.name}
            </Descriptions.Item>
            <Descriptions.Item label={t('providers.fields.status')}>
              {record?.status}
            </Descriptions.Item>
            <Descriptions.Item label={t('providers.fields.description')}>
              {record?.description}
            </Descriptions.Item>
          </Descriptions>

          {record && <AddAccountOperationButton provider_id={record?.id} />}

          <Descriptions
            column={1}
            bordered
            title={t('providers.fields.accounts')}
            style={{
              maxWidth: 800,
            }}
          >
            {record && record?.accounts?.map((value) => {
              return (
                <Descriptions.Item
                  key={value?.id}
                  label={
                    <Space>
                      {value?.currency?.code}
                      <Tag>{value?.kind}</Tag>
                    </Space>
                  }
                >
                  <Space direction='vertical'>
                    <Tag>{t('accounts.fields.balance')} {formattedAmount(value?.balance)}</Tag>
                    <Tag>{t('accounts.fields.hold_balance')} {formattedAmount(value?.hold_balance)}</Tag>
                    <Tag>{t('accounts.fields.balance_with_holds')} {formattedAmount(value?.balance_with_holds)}</Tag>
                  </Space>
                </Descriptions.Item>
              )
            })}
          </Descriptions>
        </Flex>
      </Show>
      <Tabs defaultActiveKey="1" items={tabsItems} />
    </>
  )
}
