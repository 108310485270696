import React from 'react'
import { IResourceComponentsProps, useShow, useTranslate, } from '@refinedev/core'
import { Show, EditButton, TagField } from '@refinedev/antd'
import { Descriptions, Space, Tag } from 'antd'
import { ShortId } from 'components/ShortId'
import { IProviderGateway } from 'interfaces'
import { GatewayType } from 'components/GatewayType'
import { GatewayDirection } from 'components/GatewayDirection'
import { GatewayMethod } from 'components/GatewayMethod'
import DateField from 'components/DateField'

export const ProviderGatewayShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { queryResult } = useShow<IProviderGateway>()
  const { data, isLoading } = queryResult
  const record = data?.data

  return (
    <Show
      isLoading={isLoading}
      title={record?.name || ''}
      headerButtons={<EditButton title={t('buttons.edit')} />}
    >
      <Descriptions
        column={1}
        bordered
        style={{
          maxWidth: 800,
        }}
      >
        <Descriptions.Item label={t('provider_gateways.fields.provider')}>
          <Tag style={{ fontSize: 14, }}>{record?.provider?.name}</Tag>
          {record?.provider?.id && <ShortId value={record?.provider?.id} />}
        </Descriptions.Item>
        <Descriptions.Item label={t('provider_gateways.fields.name')}>
          {record?.name}
        </Descriptions.Item>
        <Descriptions.Item label={t('provider_gateways.fields.external_method')}>
          {record?.external_method}
        </Descriptions.Item>
        {record?.gateway &&
          <Descriptions.Item label={t('provider_gateways.fields.gateway')}>
            <Space>
              <GatewayDirection direction={record?.gateway?.direction} />
              <GatewayType type={record?.gateway?.payment_type} />
              {record?.gateway?.currency}
              {record?.gateway?.name}
              <GatewayMethod method={record?.gateway?.payment_method} />
              <ShortId value={record?.gateway?.id} />
            </Space>
          </Descriptions.Item>
        }
        <Descriptions.Item label={t('provider_gateways.fields.status')}>
          <TagField color={record?.status === 'active' ? 'green' : 'red'} value={record?.status} />
        </Descriptions.Item>
        {record?.created_at && <Descriptions.Item label={t('provider_gateways.fields.created_at')}>
          <DateField style={{ fontSize: 12 }} value={record?.created_at} />
        </Descriptions.Item>}
        {record?.updated_at && <Descriptions.Item label={t('provider_gateways.fields.updated_at')}>
          <DateField style={{ fontSize: 12 }} value={record?.updated_at} />
        </Descriptions.Item>}
      </Descriptions>
    </Show>
  )
}
