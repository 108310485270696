import React from 'react'
import { accountOperationStatus } from 'interfaces'
import { Tag } from 'antd'
import {
  CheckCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons'

export const AccountOperationStatus: React.FC<{ status: accountOperationStatus }> = ({ status }) => {
  const IconMap = {
    created: <SyncOutlined spin />,
    cancelled: <MinusCircleOutlined />,
    approved: <CheckCircleOutlined />,
  }

  const ColorMap = {
    created: 'processing',
    cancelled: 'error',
    approved: 'success',
  }

  return (
    <Tag
      icon={IconMap[status]}
      color={ColorMap[status]}
    >
      {status}
    </Tag>
  )
}
