import { IResourceComponentsProps, useTranslate, } from '@refinedev/core'
import { Create, useForm, } from '@refinedev/antd'
import { Form, Input, Select, } from 'antd'
import { IProvider } from 'interfaces'
import { generalStatusOptions } from 'utils'

export const ProviderCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps, form } = useForm<IProvider>()

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    try {
      JSON.parse(e.target.value);
      form.setFieldsValue({
        credentials: JSON.stringify(JSON.parse(e.target.value), null, 2)
      })
    } catch { }
  };

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{ status: 'active' }}
      >
        <Form.Item
          label={t('providers.fields.name')}
          name="name"
          rules={[{ required: true, },]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('providers.fields.status')}
          name="status"
        >
          <Select options={generalStatusOptions} />
        </Form.Item>
        <Form.Item
          label={t('providers.fields.description')}
          name="description"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('providers.fields.credentials')}
          name="credentials"
        >
          <Input.TextArea
            rows={15}
            onChange={handleChange}
            style={{ fontFamily: "monospace" }}
          />
        </Form.Item>
      </Form>
    </Create>
  )
}
