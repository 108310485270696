import { useTranslate } from '@refinedev/core'
import { Table, } from 'antd'
import { IAccountOperationEvent } from 'interfaces'
import { formattedAmount } from 'utils'
import { ShortId } from 'components/ShortId'
import { TextField } from '@refinedev/antd'
import DateField from 'components/DateField'
import { AccountOperationStatus } from 'components/AccountOperationStatus'

export const AccountOperationEventsTable: React.FC<{
  account_operation_events: IAccountOperationEvent[]
}> = ({
  account_operation_events
}) => {
    const t = useTranslate()

    return (
      <Table
        scroll={{ x: '100%' }}
        title={() => (<>{t('components.account_operation_events_table.title')}</>)}
        dataSource={account_operation_events}
        size="small"
        rowKey="id"
        pagination={false}
      >
        <Table.Column<IAccountOperationEvent>
          width={150}
          ellipsis
          dataIndex={'status'}
          key="status"
          title={t('account_operations.fields.status')}
          render={(value) => <AccountOperationStatus status={value} />}
        />
        <Table.Column<IAccountOperationEvent>
          ellipsis
          dataIndex="amount"
          key="amount"
          width={150}
          title={t('account_operations.fields.amount')}
          render={(value) => (
            <TextField value={formattedAmount(value)} />
          )}
        />
        <Table.Column<IAccountOperationEvent>
          width={250}
          ellipsis
          dataIndex={['user', 'email']}
          key="user"
          title={t('components.account_operation_events_table.fields.user')}
        />
        <Table.Column<IAccountOperationEvent>
          width={200}
          ellipsis
          dataIndex="created_at"
          key="created_at"
          title={t('account_operations.fields.created_at')}
          render={(value) => <DateField style={{ fontSize: 12 }} value={value} />}
        />
        <Table.Column<IAccountOperationEvent>
          width={150}
          ellipsis
          dataIndex="id"
          key="id"
          title={t('account_operations.fields.id')}
          render={(value) => <ShortId value={value} />}
        />
      </Table>
    )
  }