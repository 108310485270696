import { IResourceComponentsProps, useTranslate, useList } from '@refinedev/core'
import { Create, useForm, useSelect } from '@refinedev/antd'
import { Form, Input, Select, } from 'antd'
import { ICard, IGateway, IPartner } from 'interfaces'
import { gatewayOptions } from 'components/GatewayOptions'

export const CardCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<ICard>()

  const { data: gatewaysData } = useList<IGateway>({
    resource: 'gateways',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
      {
        field: 'payment_type',
        operator: 'eq',
        value: ['payout', 'card', 'sbp', 'sbpqr', 'account', 'ecom', 'iban', 'qr', 'url', 'cvu', 'va', 'phone'],
      },
    ],
    pagination: {
      current: 1,
      pageSize: 1000,
    },
  })
  const gateways = gatewaysData?.data ?? []

  const { selectProps: partnerSelectProps } = useSelect<IPartner>({
    resource: 'partners',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          name="gateways_ids"
          label={t('cards.fields.gateways')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            showSearch
            options={gatewayOptions(gateways)}
            placeholder={t('agreements.form.gateway.placeholder')}
            filterOption={(input, option: any) =>
              (option?.desc ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          name="card_number"
          label={t('cards.fields.card_number')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input showCount allowClear />
        </Form.Item>
        <Form.Item
          name="cardholder_name"
          label={t('cards.fields.cardholder_name')}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input showCount allowClear />
        </Form.Item>
        <Form.Item
          name="phone"
          label={t('cards.fields.phone')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input showCount allowClear placeholder="+70000000000" />
        </Form.Item>
        <Form.Item label={t('cards.fields.bank_for_sbp')} name="bank_for_sbp">
          <Select
            options={[
              {
                label: 'Тиньков',
                value: 'Тиньков',
              },
              {
                label: 'Сбербанк',
                value: 'Сбербанк',
              },
              {
                label: 'Райффайзен',
                value: 'Райффайзен',
              },
            ]}
          />
        </Form.Item>
        <Form.Item label={t('cards.fields.color')} name="color">
          <Select
            options={[
              {
                label: t('cards.colors.green'),
                value: 'green',
              },
              {
                label: t('cards.colors.red'),
                value: 'red',
              },
            ]}
          />
        </Form.Item>
        <Form.Item label={t('cards.fields.sbp_holder_name')} name="sbp_holder_name">
          <Input />
        </Form.Item>
        <Form.Item label={t('cards.fields.account')} name="account">
          <Input showCount allowClear />
        </Form.Item>
        <Form.Item label={t('cards.fields.iban')} name="iban">
          <Input showCount allowClear />
        </Form.Item>
        <Form.Item
          label={t('payment_requisites.fields.ecom_telegram_chat_id')}
          name="ecom_telegram_chat_id"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="partner_id"
          label={t('cards.fields.partner')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...partnerSelectProps} />
        </Form.Item>
      </Form>
    </Create>
  )
}
