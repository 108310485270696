import React from 'react'
import { useTable } from '@refinedev/antd'
import { IAgreement } from 'interfaces'
import AgreementsTable from '../agreements/list_table'
import AddAgreementButton from 'components/AddAgreementButton'
import { Flex } from 'antd'

export const AgreementsList: React.FC<{
  merchantId: string
  currency?: string
}> = ({
  merchantId,
  currency
}) => {
    const { tableProps } = useTable<IAgreement>({
      resource: 'billing/agreements',
      filters: {
        permanent: [
          {
            field: 'merchant_id',
            operator: 'eq',
            value: merchantId,
          },
        ],
      },
    })

    return (
      <>
        <Flex
          justify='end'
        >
          <AddAgreementButton
            initialValues={{ type: 'merchant', merchant_id: merchantId }}
            currency={currency}
            style={{ marginBottom: 16 }}
          />
        </Flex>
        <AgreementsTable tableProps={tableProps}></AgreementsTable>
      </>
    )
  }
