import React from 'react'
import { gatewayMethods } from 'interfaces'
import { Tag } from 'antd'

export const GatewayMethod: React.FC<{ method: gatewayMethods }> = ({ method }) => {

  const GatewayMethodsColorMap = {
    oct: 'blue',
    p2p: 'green',
    ecom: 'orange',
    c_ecom: 'orange-inverse',
    qr: 'pink-inverse'
  }

  return (
    <Tag
      color={GatewayMethodsColorMap[method]}
      style={{ fontSize: 14 }}
    >
      {method}
    </Tag>
  )
}
