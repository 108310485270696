import {
  useTranslate,
  CrudFilters,
  useApiUrl,
  getDefaultFilter,
} from '@refinedev/core'

import { ShowButton, useTable, } from '@refinedev/antd'
import ScopesFilter from 'components/ScopesFilter'
import { Space, Form, theme, } from 'antd'
import { IPaymentCascade } from 'interfaces'
import ListSort from '../../components/ListSort/list_sort'
import '../../components/ListSort/list_sort.css'
import BooleanTag from 'components/BooleanTag'
import { TOKEN_KEY } from '../../constants'
import EditPaymentCascadeButton from 'components/EditPaymentCascadeButton'
import { GatewayType } from 'components/GatewayType'
import { GatewayDirection } from 'components/GatewayDirection'
import { GatewayMethod } from 'components/GatewayMethod'

const { useToken } = theme

export const PaymentCascades: React.FC<{
  merchantId?: string
}> = ({
  merchantId,
}) => {
    const t = useTranslate()
    const { token } = useToken()

    const apiUrl = useApiUrl()

    const { searchFormProps, tableQueryResult, filters } = useTable<IPaymentCascade>({
      resource: 'payment_cascades',
      onSearch: (params) => {
        // @ts-ignore
        const { scope } = params
        const cardsFilters: CrudFilters = []

        cardsFilters.push({
          field: 'scope',
          operator: 'eq',
          value: scope,
        })

        return cardsFilters
      },
      pagination: {
        pageSize: 1000,
      },
      filters: {
        initial: [
          {
            field: 'scope',
            operator: 'eq',
            value: 'income',
          },
        ],
        permanent: [
          {
            field: 'merchant_id',
            operator: 'eq',
            value: merchantId,
          },
        ],
      },
    })

    if (tableQueryResult?.isLoading) {
      return <div>Loading...</div>;
    }

    const childrenToRender = tableQueryResult?.data?.data.map((item, i) => {
      const {
        id, payment_system, kind, traffic_percentage, all_merchants, all_gateways, merchant, gateway
      } = item;
      return (
        <div id={id} key={i} className="list-sort-demo-list">
          <div className="list-sort-demo-text">
            <h1>{t('payment_cascades.payment_system.' + payment_system)}</h1>
            <p>
              <span style={{ fontWeight: 'bold' }}>{traffic_percentage}%</span>
              &nbsp; | &nbsp;
              {t('payment_cascades.fields.all_merchants')}: <BooleanTag value={all_merchants} />
              &nbsp; | &nbsp;
              {t('payment_cascades.fields.merchant')}: <span style={{ fontWeight: 'bold' }}>{merchant.name}</span>
              &nbsp; | &nbsp;
              {t('payment_cascades.fields.all_gateways')}: <BooleanTag value={all_gateways} />
              &nbsp; | &nbsp;
              {t('payment_cascades.fields.gateway')}:&nbsp;
              <Space>
                {gateway?.direction && <GatewayDirection direction={gateway?.direction} />}
                {gateway?.payment_type && <GatewayType type={gateway?.payment_type} />}
                {gateway?.currency}
                {gateway?.name}
                {gateway?.payment_method && <GatewayMethod method={gateway?.payment_method} />}
              </Space>
            </p>
          </div>
          <Space>
            {merchantId ? (
              <EditPaymentCascadeButton id={id} />
            ) : (
              <ShowButton
                resource={"payment_cascades"}
                size="small"
                recordItemId={id}
              />
            )}

          </Space>
        </div>
      );
    });

    const onListUpdate = (value: any) => {
      if (!(value instanceof MouseEvent)) {
        return
      }

      if (!(value.type === "mouseup")) {
        return
      }

      let priorities_arr = Array();
      tableQueryResult?.data?.data?.forEach((e) => {
        priorities_arr.push(e.priority);
      })

      const listItems = Array.from(document.querySelectorAll('.list-sort-demo-list'));

      interface CascadeOrder {
        [key: string]: number;
      }

      const dataToSend: CascadeOrder = {}

      listItems.forEach((item, index) => {
        const id = item.getAttribute('id');
        if (id) {
          dataToSend[id] = priorities_arr[index];
        }
      });

      const jsonData = JSON.stringify({ priority: dataToSend });

      fetch(`${apiUrl}/payment_cascades/update_priority`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
        body: jsonData
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => { })
        .catch(error => { console.error('There was a problem saving the data:', error); });
    }

    return (
      <>
        <Form
          {...searchFormProps}
          layout="horizontal"
          onValuesChange={() => {
            searchFormProps.form?.submit()
          }}
          initialValues={{
            scope: getDefaultFilter('scope', filters, 'eq'),
          }}
        >
          {
            // @ts-ignore
            tableQueryResult.data?.scopes && (
              <Form.Item name="scope">
                <ScopesFilter
                  // @ts-ignore
                  scopes={tableQueryResult.data?.scopes}
                  resource={'payment_cascades'}
                />
              </Form.Item>
            )
          }
        </Form>
        <div
          className="list-sort-demo-wrapper"
          style={{
            backgroundColor: token.colorBgContainerDisabled,
            marginBottom: 40,
          }}
        >
          <div className="list-sort-demo">
            <ListSort
              dragClassName="list-drag-selected"
              onListUpdate={onListUpdate}
              appearAnim={{ animConfig: { marginTop: [5, 30], opacity: [1, 0] } }}
            >
              {childrenToRender}
            </ListSort>
          </div>
        </div>
      </>
    )
  }
