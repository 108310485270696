import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Table, TableProps, Tag, Space } from 'antd'
import { IAccountEntry } from 'interfaces'
import { formattedAmount } from 'utils'
import { ShortId } from 'components/ShortId'
import { TextField } from '@refinedev/antd'
import DateField from 'components/DateField'

export const AccountEntriesTable: React.FC<
  IResourceComponentsProps & { tableProps: TableProps<IAccountEntry> }
> = ({ tableProps }) => {
  const t = useTranslate()

  const kindColor = (kind: string): string => {
    if (kind.includes('hold')) return 'blue'
    if (kind.includes('waste')) return 'orange'
    if (kind.includes('approve')) return 'green'
    return 'default'
  }

  return (
    <Table
      title={() => (<>{t('components.account_entries_table.title')}</>)}
      {...tableProps}
      size="small"
      rowKey="id"
      pagination={
        tableProps.pagination && (tableProps.pagination?.total ?? 0) > 10
          ? {
            ...tableProps.pagination,
            pageSize: 20,
            position: ['bottomLeft'],
            size: 'small',
          }
          : false
      }
    >
      <Table.Column<IAccountEntry>
        dataIndex="kind"
        key="kind"
        title={t('components.account_entries_table.fields.kind')}
        render={(value) =>
          <Tag color={kindColor(value)}>{value}</Tag>
        }
      />
      <Table.Column<IAccountEntry>
        ellipsis
        dataIndex="debit"
        key="debit"
        width={150}
        title={t('components.account_entries_table.fields.debit')}
        render={(value) => (
          <TextField style={{ color: 'green' }} value={formattedAmount(value)} />
        )}
      />
      <Table.Column<IAccountEntry>
        ellipsis
        dataIndex="credit"
        key="credit"
        width={150}
        title={t('components.account_entries_table.fields.credit')}
        render={(value) => (
          <TextField style={{ color: 'red' }} value={formattedAmount(value)} />
        )}
      />
      <Table.Column<IAccountEntry>
        ellipsis
        dataIndex="initial_balance"
        key="initial_balance"
        width={150}
        title={t('components.account_entries_table.fields.initial_balance')}
        render={(value) => (
          <Tag style={{ fontSize: 14 }}>{formattedAmount(value)}</Tag>
        )}
      />
      <Table.Column<IAccountEntry>
        ellipsis
        dataIndex="result_balance"
        key="result_balance"
        width={150}
        title={t('components.account_entries_table.fields.result_balance')}
        render={(value) => (
          <Tag style={{ fontSize: 14 }}>{formattedAmount(value)}</Tag>
        )}
      />
      <Table.Column<IAccountEntry>
        ellipsis
        dataIndex="operation_id"
        key="operation_id"
        title={t('components.account_entries_table.fields.operation_id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IAccountEntry>
        ellipsis
        dataIndex="account_operation_id"
        key="account_operation_id"
        title={t('components.account_entries_table.fields.account_operation_id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IAccountEntry>
        ellipsis
        dataIndex="account_id"
        key="account_id"
        title={t('components.account_entries_table.fields.account_id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IAccountEntry>
        ellipsis
        dataIndex="storno_id"
        key="storno_id"
        title={t('components.account_entries_table.fields.storno_id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IAccountEntry>
        ellipsis
        dataIndex="created_at"
        key="created_at"
        title={t('components.account_entries_table.fields.created_at')}
        render={(value) => <DateField style={{ fontSize: 12 }} value={value} />}
      />
      <Table.Column<IAccountEntry>
        ellipsis
        dataIndex="id"
        key="id"
        title={t('components.account_entries_table.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
    </Table>
  )
}