import React from 'react'
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
  useApiUrl,
  useCustomMutation,
  useInvalidate,
} from '@refinedev/core'
import { EditButton, Show, useTable } from '@refinedev/antd'
import { Descriptions, Space, Flex, Tag, Button, Popconfirm, } from 'antd'
import { IAccountOperation, GenericResponse, IAccountEntry } from 'interfaces'
import { formattedCurrencyAmount } from 'utils'
import { TextField } from '@refinedev/antd'
import DateField from 'components/DateField'
import { ShortId } from 'components/ShortId'
import { AccountOperationStatus } from 'components/AccountOperationStatus'
import { AccountOperationKind } from 'components/AccountOperationKind'
import TextArea from 'antd/es/input/TextArea'
import { AccountOperationEventsTable } from 'components/AccountOperationEventsTable'
import { AccountEntriesTable } from 'components/AccountEntriesTable'

const AccountEntries: React.FC<{ account_operation: IAccountOperation }> = ({ account_operation }) => {
  const t = useTranslate()

  const { tableProps } = useTable<IAccountEntry>({
    resource: 'account_entries',
    filters: {
      permanent: [
        {
          field: 'account_operation_id',
          operator: 'eq',
          value: account_operation.id,
        },
      ]
    },
    pagination: {
      pageSize: 20,
    },
  })

  return (
    <AccountEntriesTable tableProps={tableProps} />
  )
}

export const AccountOperationShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { queryResult } = useShow<IAccountOperation>()
  const { data, isLoading } = queryResult
  const record = data?.data

  const apiUrl = useApiUrl()
  const invalidate = useInvalidate()
  const { mutate } = useCustomMutation<GenericResponse>()

  const setStatus = (status: string) => {
    mutate(
      {
        url: `${apiUrl}/account_operations/${record?.id}/${status}`,
        method: 'post',
        values: {},
      },
      {
        onError: () => { },
        onSuccess: () => {
          invalidate({
            resource: 'account_operations',
            invalidates: ['detail'],
            id: record?.id,
          })
          invalidate({
            resource: 'account_entries',
            invalidates: ['list'],
          })
        },
      }
    )
  }

  const headerButtons = () => {
    if (!record) {
      return
    }
    return (
      <>
        {record?.status === 'created' &&
          <EditButton
            resource="account_operations"
            recordItemId={record.id}
            title={t('buttons.edit')}
          />
        }
      </>
    )
  }

  return (
    <Show
      isLoading={isLoading}
      title={record?.created_at ? (
        <DateField value={record?.created_at} />
      ) : (
        ''
      )}
      headerButtons={headerButtons}
    >
      {record &&
        <Flex
          vertical
          gap={40}
        >
          {record?.status === 'created' &&
            <Flex justify='space-evenly'>
              <Popconfirm
                title={t('account_operations.confirm.approve.title')}
                description={t('account_operations.confirm.approve.description')}
                okText={t('account_operations.confirm.approve.ok')}
                cancelText={t('account_operations.confirm.approve.cancel')}
                onConfirm={() => setStatus('approve')}
              >
                <Button
                  type="primary"
                  style={{ background: 'green' }}
                >
                  {t('account_operations.buttons.approve')}
                </Button>
              </Popconfirm>
              <Popconfirm
                title={t('account_operations.confirm.cancel.title')}
                description={t('account_operations.confirm.cancel.description')}
                okText={t('account_operations.confirm.cancel.ok')}
                cancelText={t('account_operations.confirm.cancel.cancel')}
                onConfirm={() => setStatus('cancel')}
              >
                <Button
                  type="primary"
                  style={{ background: 'red' }}
                >
                  {t('account_operations.buttons.cancel')}
                </Button>
              </Popconfirm>
            </Flex>
          }
          <Descriptions bordered>
            <Descriptions.Item label={t('account_operations.fields.id')}>
              <ShortId value={record?.id} />
            </Descriptions.Item>
            <>
              <Descriptions.Item label={t('account_operations.fields.amount')}>
                <TextField
                  style={{ fontSize: 18, fontWeight: 'bold' }}
                  value={formattedCurrencyAmount(record?.amount, 2, record?.account?.currency?.code)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t('account_operations.fields.kind')}>
                <AccountOperationKind kind={record.kind} />
              </Descriptions.Item>
              <Descriptions.Item label={t('account_operations.fields.status')}>
                <AccountOperationStatus status={record.status} />
              </Descriptions.Item>
              <Descriptions.Item label={t('account_operations.fields.created_at')}>
                <DateField style={{ fontWeight: 'bold', fontSize: 12, }} value={record?.created_at} />
              </Descriptions.Item>
              <Descriptions.Item label={t('account_operations.fields.approved_at')}>
                <DateField style={{ fontWeight: 'bold', color: 'green', fontSize: 12, }} value={record?.approved_at} />
              </Descriptions.Item>
              <Descriptions.Item label={t('account_operations.fields.member')}>
                <Space direction='vertical' size={1}>
                  {record?.merchant?.id && (
                    <>
                      {t('account_operations.fields.merchant')}
                      <Tag><ShortId value={record?.merchant?.id} /></Tag>
                      <Tag color='purple'>{record?.merchant?.name}</Tag>
                    </>
                  )}
                  {record?.team?.id && (
                    <>
                      {t('account_operations.fields.team')}
                      <Tag><ShortId value={record?.team?.id} /></Tag>
                      <Tag color='geekblue'>{record?.team?.name}</Tag>
                    </>
                  )}
                  {record?.provider?.id && (
                    <>
                      {t('account_operations.fields.provider')}
                      <Tag><ShortId value={record?.provider?.id} /></Tag>
                      <Tag color='blue'>{record?.provider?.name}</Tag>
                    </>
                  )}
                  {record?.agent?.id && (
                    <>
                      {t('account_operations.fields.agent')}
                      <Tag><ShortId value={record?.agent?.id} /></Tag>
                      <Tag>{record?.agent?.name}</Tag>
                    </>
                  )}
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label={t('account_operations.fields.currency')}>
                {record?.account?.currency?.code}
              </Descriptions.Item>
              <Descriptions.Item label={t('account_operations.fields.account')}>
                <Space direction='vertical' size={1}>
                  <Tag><ShortId value={record?.account?.id} /></Tag>
                  <Tag>{record?.account?.kind}</Tag>
                </Space>
              </Descriptions.Item>
            </>
          </Descriptions>
          <Descriptions layout='vertical' bordered>
            <Descriptions.Item label={t('account_operations.fields.comment')}>
              <TextArea contentEditable={false} rows={10} value={record?.comment} />
            </Descriptions.Item>
          </Descriptions>
          <AccountOperationEventsTable account_operation_events={record?.account_operation_events} />
          <AccountEntries account_operation={record} />
        </Flex>
      }
    </Show>
  )
}
