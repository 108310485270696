import { useCallback, useState } from 'react'
import { useTranslate } from '@refinedev/core'
import { Radio } from 'antd'

const ScopesFilter: React.FC<{
  scopes: { [key: string]: number }
  value?: string
  onChange?: (value: string) => void
  resource: string
  default_scope?: string
}> = ({ onChange, value, scopes, resource, default_scope }) => {
  const t = useTranslate()
  const [scope, setScope] = useState<string>(value ?? (default_scope || 'all'))

  const onRadioChange = useCallback(
    (scope: any) => {
      setScope(scope)
      onChange && onChange(scope)
    },
    [onChange]
  )

  return (
    <Radio.Group
      size="middle"
      onChange={(e) => onRadioChange(e.target.value)}
      value={scope}
      buttonStyle="solid"
    >
      {Object.keys(scopes).map((scope_key) => (
        <Radio.Button key={scope_key} value={scope_key}>
          {t(`${resource}.filter.scope.${scope_key}`)}
          {/* {`(${scopes[scope_key]})`} */}
        </Radio.Button>
      ))}
    </Radio.Group>
  )
}

export default ScopesFilter
