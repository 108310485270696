import {
  IResourceComponentsProps,
  useNavigation,
  useTranslate,
  useList
} from '@refinedev/core'

import { Edit, useForm } from '@refinedev/antd'
import { Form, Input, Select, } from 'antd'
import { ICard, IGateway } from 'interfaces'
import { formattedCardNumber } from 'utils'
import { gatewayOptions } from 'components/GatewayOptions'

export const CardEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { formProps, onFinish } = useForm<ICard>({
    action: 'edit',
    redirect: false,
  })

  const { goBack } = useNavigation()

  const onClick = () => {
    onFinish().then(() => {
      goBack()
    })
  }

  const { data: gatewaysData } = useList<IGateway>({
    resource: 'gateways',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
      {
        field: 'payment_type',
        operator: 'eq',
        value: ['payout', 'card', 'sbp', 'sbpqr', 'account', 'ecom', 'iban', 'qr', 'url', 'cvu', 'va', 'phone'],
      },
    ],
    pagination: {
      current: 1,
      pageSize: 1000,
    },
  })
  const gateways = gatewaysData?.data ?? []

  return (
    <Edit saveButtonProps={{ onClick }}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          name="gateways_ids"
          label={t('payment_requisites.fields.gateways')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            showSearch
            options={gatewayOptions(gateways)}
            placeholder={t('agreements.form.gateway.placeholder')}
            filterOption={(input, option: any) =>
              (option?.desc ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item label={t('payment_requisites.fields.card_number')}>
          <Input
            disabled
            value={
              formProps?.initialValues?.card_number
                ? formattedCardNumber(formProps?.initialValues?.card_number)
                : ''
            }
          />
        </Form.Item>
        <Form.Item
          label={t('payment_requisites.fields.cardholder_name')}
          name="cardholder_name"
        >
          <Input />
        </Form.Item>
        <Form.Item label={t('payment_requisites.fields.phone')} name="phone">
          <Input disabled />
        </Form.Item>
        <Form.Item
          label={t('payment_requisites.fields.bank_for_sbp')}
          name="bank_for_sbp"
        >
          <Select
            options={[
              {
                label: 'Тиньков',
                value: 'Тиньков',
              },
              {
                label: 'Сбербанк',
                value: 'Сбербанк',
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label={t('payment_requisites.fields.sbp_holder_name')}
          name="sbp_holder_name"
        >
          <Input />
        </Form.Item>
        <Form.Item label={t('payment_requisites.fields.account')} name="account">
          <Input showCount allowClear />
        </Form.Item>
        <Form.Item label={t('cards.fields.iban')} name="iban">
          <Input showCount allowClear />
        </Form.Item>
        <Form.Item
          label={t('payment_requisites.fields.ecom_telegram_chat_id')}
          name="ecom_telegram_chat_id"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="max_approved_sum"
          label={t('cards.fields.max_approved_sum')}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t('cards.fields.color')} name="color">
          <Select
            options={[
              {
                label: t('cards.colors.green'),
                value: 'green',
              },
              {
                label: t('cards.colors.red'),
                value: 'red',
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Status"
          name="status"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={[
              {
                label: 'created',
                value: 'created',
              },
              {
                label: 'active',
                value: 'active',
              },
              {
                label: 'suspended',
                value: 'suspended',
              },
              {
                label: 'blocked',
                value: 'blocked',
              },
            ]}
          />
        </Form.Item>
      </Form>
    </Edit>
  )
}
