import { useState } from 'react'

import {
  IResourceComponentsProps,
  useTranslate,
  CrudFilters,
  getDefaultFilter,
  useCan,
  useList
} from '@refinedev/core'

import { useTable, useSelect } from '@refinedev/antd'

import {
  Col,
  Row,
  Form,
  Select,
  SelectProps,
  Input,
  DatePicker,
  InputNumber,
  Button,
  Space,
  Radio
} from 'antd'

import { CaretDownOutlined, CaretRightOutlined, SearchOutlined } from '@ant-design/icons'
import locale from 'antd/es/date-picker/locale/ru_RU'
import dayjs from 'dayjs'

import { IMerchant, IOperation, IPartner, ICurrency } from 'interfaces'
import OperationsTable from './list_table'
import { OperationStatusMap } from 'pages/cashin/OperationStatus'
import ScopesFilter from 'components/ScopesFilter'

const { RangePicker } = DatePicker

export const PayoutsList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const [filtersExpanded, setFiltersExpanded] = useState(false)

  const { tableProps, searchFormProps, filters, tableQueryResult } = useTable<IOperation>(
    {
      resource: 'operations',
      filters: {
        initial: [
          {
            field: 'currency_code',
            operator: 'eq',
            value: 'all',
          },
        ],
        permanent: [
          {
            field: 'kind',
            operator: 'eq',
            value: 'payout',
          },
        ],
      },
      onSearch: (params: any) => {
        const filters: CrudFilters = []
        const {
          currency_code,
          statuses,
          id,
          idempotency_key,
          card_number,
          rand,
          merchants_ids,
          created_at,
          amount_gte,
          amount_lte,
          partners_ids,
          scope,
        } = params

        filters.push({
          field: 'currency_code',
          operator: 'eq',
          value: currency_code,
        })

        filters.push({
          field: 'statuses',
          operator: 'in',
          value: statuses,
        })

        filters.push({
          field: 'id',
          operator: 'eq',
          value: id,
        })

        filters.push({
          field: 'idempotency_key',
          operator: 'eq',
          value: idempotency_key,
        })

        filters.push({
          field: 'card_number',
          operator: 'eq',
          value: card_number,
        })

        filters.push({
          field: 'rand',
          operator: 'eq',
          value: rand,
        })

        filters.push({
          field: 'merchants_ids',
          operator: 'in',
          value: merchants_ids,
        })

        filters.push({
          field: 'amount_gte',
          operator: 'eq',
          value: amount_gte,
        })

        filters.push({
          field: 'amount_lte',
          operator: 'eq',
          value: amount_lte,
        })

        filters.push({
          field: 'created_at_gte',
          operator: 'eq',
          value:
            created_at && created_at[0]
              ? dayjs(created_at[0]).tz(undefined, true).startOf('day').toISOString()
              : null,
        })

        filters.push({
          field: 'created_at_lte',
          operator: 'eq',
          value:
            created_at && created_at[1]
              ? dayjs.utc(created_at[1]).tz().endOf('day').toISOString()
              : null,
        })

        filters.push({
          field: 'partners_ids',
          operator: 'in',
          value: partners_ids?.length > 0 ? partners_ids : undefined,
        })

        filters.push({
          field: 'scope',
          operator: 'eq',
          value: scope,
        })

        return filters
      },

      pagination: {
        pageSize: 20,
      },
    }
  )

  var timerId: any

  const debounceFunction = (func: any, delay: number) => {
    clearTimeout(timerId)
    timerId = setTimeout(func, delay)
  }

  const onValueChange = () => {
    debounceFunction(() => {
      searchFormProps.form?.submit()
    }, 400)
  }

  const { data: canFilterByScope } = useCan({
    resource: 'operations',
    action: 'scope_filter',
  })

  const options: SelectProps['options'] = Object.keys(OperationStatusMap).map((key) => ({
    value: key,
    // @ts-ignore
    label: t(OperationStatusMap[key]),
  }))

  const { data: canViewPartners } = useCan({
    resource: 'partners',
    action: 'filter',
  })

  const { data: canViewMerchants } = useCan({
    resource: 'merchants',
    action: 'filter',
  })

  const { selectProps: partnersSelectProps } = useSelect<IPartner>({
    resource: 'partners',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 100000,
    },
    queryOptions: {
      enabled: !!canViewPartners?.can,
    },
  })

  const { selectProps: merchantsSelectProps } = useSelect<IMerchant>({
    resource: 'merchants',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 10000,
    },
    queryOptions: {
      enabled: !!canViewMerchants?.can,
    },
  })

  const { data: currenciesData } = useList<ICurrency>({
    resource: 'currencies',
    pagination: {
      current: 1,
      pageSize: 1000,
    },
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
  })
  const currencies = currenciesData?.data ?? []

  return (
    <>
      <Form
        {...searchFormProps}
        layout="vertical"
        size={'middle'}
        onValuesChange={onValueChange}
        initialValues={{
          currency_code: getDefaultFilter('currency_code', filters),
          partners_ids: getDefaultFilter('partners_ids', filters, 'in'),
          statuses: getDefaultFilter('statuses', filters, 'in'),
          id: getDefaultFilter('id', filters),
          idempotency_key: getDefaultFilter('idempotency_key', filters),
          card_number: getDefaultFilter('card_number', filters),
          merchants_ids: getDefaultFilter('merchants_ids', filters),
          amount_lte: getDefaultFilter('amount_lte', filters),
          amount_gte: getDefaultFilter('amount_gte', filters),
          scope: getDefaultFilter('scope', filters),
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item
            name="id"
            style={{
              flexGrow: 1,
              marginRight: 16,
            }}
          >
            <Input
              placeholder={t('operations.filter.id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="idempotency_key"
            style={{
              flexGrow: 1,
              marginRight: 16,
            }}
          >
            <Input
              placeholder={t('operations.filter.idempotency_key.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Button
            icon={filtersExpanded ? <CaretDownOutlined /> : <CaretRightOutlined />}
            onClick={() => {
              filtersExpanded ? setFiltersExpanded(false) : setFiltersExpanded(true)
            }}
          >
            {t('operations.titles.filters')}
          </Button>
        </div>
        <div
          style={{
            overflow: 'hidden',
            maxHeight: filtersExpanded ? 300 : 0,
            transition: 'max-height 1000ms',
          }}
        >
          <Form.Item style={{ marginBottom: 8 }}>
            <Form.Item name={'card_number'} style={{ marginBottom: 0 }}>
              <Input
                placeholder={t('operations.filter.card_number.placeholder')}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
          </Form.Item>
          <Form.Item style={{ marginBottom: 8 }}>
            {canViewPartners?.can && (
              <Form.Item
                name="partners_ids"
                style={{
                  marginBottom: 0,
                  display: 'inline-block',
                  width: 'calc(50% - 8px)',
                  marginRight: 16,
                }}
              >
                <Select
                  style={{ minWidth: 200 }}
                  allowClear
                  showSearch
                  placeholder={t('operations.filter.partner.placeholder')}
                  mode="multiple"
                  {...partnersSelectProps}
                />
              </Form.Item>
            )}
            {canViewMerchants?.can && (
              <Form.Item
                name="merchants_ids"
                style={{
                  marginBottom: 0,
                  display: 'inline-block',
                  width: 'calc(50% - 8px)',
                }}
              >
                <Select
                  style={{ minWidth: 200 }}
                  allowClear
                  showSearch
                  placeholder={t('operations.filter.merchant.placeholder')}
                  mode="multiple"
                  {...merchantsSelectProps}
                  filterOption={(input, option: any) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
          <Row>
            <Col span={7}>
              <Form.Item label={t('operations.filter.amount.label')} name={'amount'}>
                <Space.Compact>
                  <Form.Item noStyle name={'amount_gte'}>
                    <InputNumber
                      style={{ width: '50%' }}
                      placeholder={t('operations.filter.amount_gte.placeholder')}
                    />
                  </Form.Item>
                  <Form.Item noStyle name={'amount_lte'}>
                    <InputNumber
                      style={{ display: 'inline-block', width: '50%' }}
                      placeholder={t('operations.filter.amount_lte.placeholder')}
                    />
                  </Form.Item>
                </Space.Compact>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={t('operations.filter.status.label')} name="statuses">
                <Select
                  mode="multiple"
                  placeholder={t('operations.filter.status.placeholder')}
                  options={options}
                />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item
                label={t('operations.filter.created_at.label')}
                name="created_at"
              >
                <Form.Item name="created_at">
                  <RangePicker allowEmpty={[true, true]} locale={locale} />
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
        </div>

        {canFilterByScope?.can &&
          // @ts-ignore
          tableQueryResult.data?.scopes && (
            <Form.Item name="scope" style={{ marginBottom: 10, }}>
              <ScopesFilter
                // @ts-ignore
                scopes={tableQueryResult.data?.scopes}
                resource={'operations'}
              />
            </Form.Item>
          )}

        <Form.Item name="currency_code" style={{ marginBottom: 10, }}>
          <Radio.Group
            size="middle"
            buttonStyle="solid"
          >
            <Radio.Button
              key={'ALL'}
              value={'all'}
            >
              {'All'}
            </Radio.Button>
            {currencies.map((currency) => (
              <Radio.Button
                key={currency.code}
                value={currency.code}
              >
                {currency.code}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>
      </Form>
      <OperationsTable tableProps={tableProps} canViewMerchants={canViewMerchants} />
    </>
  )
}
