import { IResourceComponentsProps, useTranslate } from '@refinedev/core'

import { Table, TableProps, Grid } from 'antd'
import { IAgent } from 'interfaces'

import { ShortId } from 'components/ShortId'
import { ShowButton } from '@refinedev/antd'

const AgentsTable: React.FC<
  IResourceComponentsProps & {
    tableProps: TableProps<IAgent>
  }
> = ({ tableProps }) => {
  const t = useTranslate()

  const breakpoint = Grid.useBreakpoint();
  const isMobile = typeof breakpoint.lg === "undefined" ? true : !breakpoint.lg;

  return (
    <Table
      {...tableProps}
      size="small"
      rowKey="id"
      pagination={{
        ...tableProps.pagination,
        pageSize: 10,
        position: ['bottomLeft'],
        size: 'small',
      }}
    >
      <Table.Column<IAgent>
        dataIndex="id"
        ellipsis
        key="id"
        title={t('agents.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IAgent>
        dataIndex="name"
        ellipsis
        key="name"
        title={t('agents.fields.name')}
      />
      <Table.Column<IAgent>
        fixed="right"
        align="center"
        dataIndex="actions"
        key="actions"
        render={(_, record) =>
          <ShowButton
            hideText={isMobile}
            size="small"
            recordItemId={record.id}
          />
        }
      />
    </Table>
  )
}

export default AgentsTable
