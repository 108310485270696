import React from 'react'
import { IResourceComponentsProps, useCan, useShow, useTranslate } from '@refinedev/core'
import { EditButton, Show } from '@refinedev/antd'
import { Descriptions, Space, Statistic, Typography, Flex, Tag } from 'antd'
import { ITeam } from 'interfaces'
import { formattedCurrencyAmount, formattedAmount } from 'utils'
import BooleanTag from 'components/BooleanTag'
import AddAccountOperationButton from 'components/AddAccountOperationButton'

const { Text } = Typography

export const TeamShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { queryResult } = useShow<ITeam>()
  const { data, isLoading } = queryResult
  const record = data?.data

  const { data: canListAccounts } = useCan({
    resource: 'accounts',
    action: 'list',
  })

  const headerButtons = () => {
    if (!record) {
      return
    }
    return (
      <>
        <EditButton
          resource="teams"
          recordItemId={record.id}
          title={t('buttons.edit')}
        />
      </>
    )
  }

  return (
    <>
      <Show
        isLoading={isLoading}
        title={record?.name || ''}
        headerButtons={headerButtons}
      >
        <Flex
          vertical
          gap={40}
        >
          <Space wrap style={{ alignItems: 'center' }} >
            <Statistic
              title={
                <>
                  {t('teams.fields.balance')}
                </>
              }
              value={
                record && formattedCurrencyAmount(record.deposit_balance, 2, record.currency)
              }
              precision={2}
            />
          </Space>
          {canListAccounts?.can &&
            <>
              <Descriptions
                column={1}
                bordered
                style={{
                  maxWidth: 800,
                }}
              >
                <Descriptions.Item label={t('teams.fields.balance') + ' V2'}>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      fontSize: 22,
                    }}
                  >
                    {record && formattedCurrencyAmount(record?.deposit_balance_v2, 2, record.currency)}
                  </Text>
                </Descriptions.Item>
              </Descriptions>
              {record && <AddAccountOperationButton team_id={record?.id} />}
              <Descriptions
                column={1}
                bordered
                title={t('teams.fields.accounts')}
                style={{
                  maxWidth: 800,
                }}
              >
                {record && record?.accounts?.map((value) => {
                  return (
                    <Descriptions.Item
                      key={value?.id}
                      label={
                        <Space>
                          {value?.currency?.code}
                          <Tag>{value?.kind}</Tag>
                        </Space>
                      }
                    >
                      <Space direction='vertical'>
                        <Tag>{t('accounts.fields.balance')} {formattedAmount(value?.balance)}</Tag>
                        <Tag>{t('accounts.fields.hold_balance')} {formattedAmount(value?.hold_balance)}</Tag>
                        <Tag>{t('accounts.fields.balance_with_holds')} {formattedAmount(value?.balance_with_holds)}</Tag>
                      </Space>
                    </Descriptions.Item>
                  )
                })}
              </Descriptions>
            </>
          }

          <Descriptions
            column={1}
            bordered
            style={{
              maxWidth: 800,
            }}
          >
            <Descriptions.Item label={t('teams.fields.name')}>
              {record?.name}
            </Descriptions.Item>
            <Descriptions.Item label={t('teams.fields.currency')}>
              {record?.currency}
            </Descriptions.Item>
            <Descriptions.Item label={t('merchants.fields.telegram_chat_id')}>
              {record?.telegram_chat_id}
            </Descriptions.Item>
            <Descriptions.Item label={t('teams.fields.trade_orders_enabled')}>
              <BooleanTag value={record?.trade_orders_enabled} />
            </Descriptions.Item>
          </Descriptions>
        </Flex>
      </Show>
    </>
  )
}
