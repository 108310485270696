import React from 'react'
import { accountOperationKind } from 'interfaces'
import { Tag } from 'antd'

export const AccountOperationKind: React.FC<{ kind: accountOperationKind }> = ({ kind }) => {
  const ColorMap = {
    deposit: 'green',
    withdraw: 'red'
  }

  return (
    <Tag
      color={ColorMap[kind]}
    >
      {kind}
    </Tag>
  )
}
