import { useCallback, useState } from 'react'
import { useTranslate } from '@refinedev/core'
import { Radio } from 'antd'

export const OperationKindFilter: React.FC<{
  operation_kinds: string[]
  value?: string
  onChange?: (value: string) => void
  default_operation_kind?: string
}> = ({ onChange, value, operation_kinds, default_operation_kind }) => {
  const t = useTranslate()
  const [scope, setScope] = useState<string>(value ?? (default_operation_kind || 'all'))

  const onRadioChange = useCallback(
    (scope: any) => {
      setScope(scope)
      onChange && onChange(scope)
    },
    [onChange],
  )

  return (
    <Radio.Group
      size="middle"
      onChange={(e) => onRadioChange(e.target.value)}
      value={scope}
      buttonStyle="solid"
    >
      {operation_kinds.map((operation_kind) => (
        <Radio.Button key={operation_kind} value={operation_kind}>
          {t(`trade_orders.kinds.${operation_kind}`)}
        </Radio.Button>
      ))}
    </Radio.Group>
  )
}
