import { SelectProps, Space } from 'antd'
import { IGateway, } from 'interfaces'
import { GatewayType } from 'components/GatewayType'
import { GatewayDirection } from 'components/GatewayDirection'
import { GatewayMethod } from 'components/GatewayMethod'
import { DefaultOptionType } from 'antd/es/select'

export const gatewayOptions = (gateways: IGateway[] | undefined): DefaultOptionType[] | undefined => {
  const gatewayOptions: SelectProps['options'] = gateways?.map((gateway) => ({
    label: (
      <Space>
        <GatewayDirection direction={gateway.direction} />
        <GatewayType type={gateway.payment_type} />
        {gateway.currency}
        {gateway.name}
        <GatewayMethod method={gateway.payment_method} />
      </Space>
    ),
    desc: (
      gateway?.name + ' ' +
      gateway?.payment_type + ' ' +
      gateway?.currency + ' ' +
      gateway?.direction + ' ' +
      gateway?.payment_method
    ),
    value: gateway.id,
  }))

  return gatewayOptions
}