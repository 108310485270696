import { IResourceComponentsProps, useTranslate, useList, } from '@refinedev/core'
import { Create, useForm, } from '@refinedev/antd'
import { Form, Input, Select, } from 'antd'
import { IProviderGateway, IGateway, IProvider } from 'interfaces'
import { generalStatusOptions } from 'utils'
import { gatewayOptions } from 'components/GatewayOptions'
import { SelectProps, } from 'antd'

export const ProviderGatewayCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps, } = useForm<IProviderGateway>()

  const { data: gatewaysData } = useList<IGateway>({
    resource: 'gateways',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
      {
        field: 'payment_type',
        operator: 'eq',
        value: ['payout', 'card', 'sbp', 'sbpqr', 'account', 'ecom', 'iban', 'qr', 'url', 'cvu', 'va', 'phone'],
      },
    ],
    pagination: {
      current: 1,
      pageSize: 1000,
    },
  })
  const gateways = gatewaysData?.data ?? []

  const { data: providersData } = useList<IProvider>({
    resource: 'providers',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 1000,
    },
  })
  const providers = providersData?.data ?? []

  const providerOptions: SelectProps['options'] = providers?.map((provider) => ({
    label: provider?.name,
    desc: provider?.name,
    value: provider.id,
  }))

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
      >
        <Form.Item
          label={t('provider_gateways.fields.name')}
          name="name"
          rules={[{ required: true, message: '' },]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('provider_gateways.fields.external_method')}
          name="external_method"
          rules={[{ required: true, message: '' },]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('provider_gateways.fields.provider')}
          name="provider_id"
          rules={[{ required: true, message: '' },]}
        >
          <Select
            allowClear
            showSearch
            options={providerOptions}
            placeholder={t('agreements.filter.provider.placeholder')}
            filterOption={(input, option: any) =>
              (option?.desc ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          name="gateway_id"
          label={t('provider_gateways.fields.gateway')}
          rules={[{ required: true, message: '' },]}
        >
          <Select
            allowClear
            showSearch
            options={gatewayOptions(gateways)}
            placeholder={t('agreements.form.gateway.placeholder')}
            filterOption={(input, option: any) =>
              (option?.desc ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label={t('provider_gateways.fields.status')}
          name="status"
          rules={[{ required: true, message: '' },]}
        >
          <Select options={generalStatusOptions} />
        </Form.Item>
      </Form>
    </Create>
  )
}
