import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Edit, ListButton, useForm } from '@refinedev/antd'
import { Form, Input, Select, } from 'antd'
import { IGateway } from 'interfaces'
import { gatewayDirectionOptions, gatewayPaymentMethodOptions, gatewayPaymentTypeOptions, generalStatusOptions } from 'utils'

export const GatewayEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<IGateway>({
    action: 'edit',
    redirect: 'show',
  })

  return (
    <Edit
      headerButtons={<ListButton />}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t('gateways.fields.name')}
          name="name"
          rules={[{ required: true, },]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('gateways.fields.direction')}
          name="direction"
          rules={[{ required: true, message: '' },]}
        >
          <Select options={gatewayDirectionOptions} />
        </Form.Item>
        <Form.Item
          label={t('gateways.fields.payment_method')}
          name="payment_method"
          rules={[{ required: true, message: '' },]}
        >
          <Select options={gatewayPaymentMethodOptions} />
        </Form.Item>
        <Form.Item
          label={t('gateways.fields.payment_type')}
          name="payment_type"
          rules={[{ required: true, message: '' },]}
        >
          <Select options={gatewayPaymentTypeOptions} />
        </Form.Item>
        <Form.Item
          label={t('gateways.fields.status')}
          name="status"
          rules={[{ required: true, message: '' },]}
        >
          <Select options={generalStatusOptions} />
        </Form.Item>
        <Form.Item
          label={t('gateways.fields.description')}
          name="description"
        >
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  )
}
