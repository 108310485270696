import { IResourceComponentsProps, useTranslate, useList } from '@refinedev/core'
import { Create, useForm } from '@refinedev/antd'
import { Checkbox, Form, Input, Select, InputNumber } from 'antd'
import { timeZoneOptions, } from 'utils'

import { IMerchant, ICurrency } from 'interfaces'

export const MerchantCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<IMerchant>()

  const { data: currenciesData } = useList<ICurrency>({
    resource: 'currencies',
    pagination: {
      current: 1,
      pageSize: 1000,
    },
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
  })
  const currencies = currenciesData?.data ?? []

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          interval_in_minutes: 10,
          after_ecom_timeout_in_minutes: 1,
          fill_from_timeout_in_minutes: 4,
          confirm_ecom_timeout_in_minutes: 2,
          payouts_sla_in_minutes: 60,
          auto_approve_limit: 15000,
          timezone: 'Europe/Moscow',
        }}
      >
        <Form.Item
          label={t('merchants.fields.name')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          name="currency"
          label={t('merchants.fields.currency')}
        >
          <Select
            showSearch
            options={currencies.map((currency) => ({
              label: currency.code,
              value: currency.code,
            }))}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          name="timezone"
          label={t('merchants.fields.timezone')}
        >
          <Select
            showSearch
            //@ts-ignore
            options={timeZoneOptions}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          label={t('merchants.fields.interval_in_minutes')}
          name="interval_in_minutes"
        >
          <InputNumber placeholder={t('merchants.fields.interval_in_minutes')} min={1} max={100} />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          label={t('merchants.fields.after_ecom_timeout_in_minutes')}
          name="after_ecom_timeout_in_minutes"
        >
          <InputNumber placeholder={t('merchants.fields.after_ecom_timeout_in_minutes')} min={1} max={100} />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          label={t('merchants.fields.fill_from_timeout_in_minutes')}
          name="fill_from_timeout_in_minutes"
        >
          <InputNumber placeholder={t('merchants.fields.fill_from_timeout_in_minutes')} min={1} max={100} />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          label={t('merchants.fields.confirm_ecom_timeout_in_minutes')}
          name="confirm_ecom_timeout_in_minutes"
        >
          <InputNumber placeholder={t('merchants.fields.confirm_ecom_timeout_in_minutes')} min={1} max={100} />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          label={t('merchants.fields.payouts_sla_in_minutes')}
          name="payouts_sla_in_minutes"
        >
          <InputNumber placeholder={t('merchants.fields.payouts_sla_in_minutes')} min={1} max={10000} />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          label={t('merchants.fields.auto_approve_limit')}
          name="auto_approve_limit"
        >
          <InputNumber placeholder={t('merchants.fields.auto_approve_limit')} min={1} max={10000000} />
        </Form.Item>
        <Form.Item
          label={t('merchants.fields.antifraud_enabled')}
          valuePropName="checked"
          name="antifraud_enabled"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          label={t('merchants.fields.auris_shop_id')}
          name="auris_shop_id"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="flexpay"
          valuePropName="checked"
          label={t('merchants.fields.flexpay')}
          help={t('merchants.form.flexpay.help')}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          label={t('merchants.fields.telegram_chat_id')}
          name="telegram_chat_id"
          help={t('merchants.form.telegram_chat_id.help')}
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  )
}
