import { IResourceComponentsProps, useTranslate, useCan } from '@refinedev/core'
import { Table, TableProps, Tag, Space, Flex } from 'antd'
import { useTable } from '@refinedev/antd'
import { useState } from 'react'
import { IAccount, IAccountEntry } from 'interfaces'
import { formattedCurrencyAmount } from 'utils'
import { ShortId } from 'components/ShortId'
import { TextField } from '@refinedev/antd'
import DateField from 'components/DateField'
import { AccountEntriesTable } from 'components/AccountEntriesTable'

const MoreInfo: React.FC<{ account: IAccount }> = ({ account }) => {
  const { tableProps } = useTable<IAccountEntry>({
    resource: 'account_entries',
    filters: {
      permanent: [
        {
          field: 'account_id',
          operator: 'eq',
          value: account.id,
        },
      ]
    },
    pagination: {
      pageSize: 20,
    },
  })

  return (
    <Flex
      vertical
      gap={15}
      style={{
        margin: '15px 0 15px 0'
      }}
    >
      <AccountEntriesTable tableProps={tableProps} />
    </Flex>
  )
}

const AccountsTable: React.FC<
  IResourceComponentsProps & { tableProps: TableProps<IAccount> }
> = ({ tableProps }) => {
  const t = useTranslate()

  const { data: canListAccountEntries } = useCan({
    resource: 'account_entries',
    action: 'list',
  })

  const [expandedKey, setExpandedKey] = useState(String)
  const onExpand = (_: any, record: IAccount) => {
    expandedKey === record.id ? setExpandedKey('') : setExpandedKey(record.id)
  }

  return (
    <Table
      {...tableProps}
      size="small"
      rowKey="id"
      expandable={{
        expandedRowRender: (account) => (
          <MoreInfo account={account} />
        ),
        onExpand,
        expandedRowKeys: [expandedKey],
        showExpandColumn: canListAccountEntries?.can
      }}
      pagination={
        tableProps.pagination && (tableProps.pagination?.total ?? 0) > 10
          ? {
            ...tableProps.pagination,
            pageSize: 20,
            position: ['bottomLeft'],
            size: 'small',
          }
          : false
      }
    >
      <Table.Column<IAccount>
        ellipsis
        dataIndex="id"
        key="id"
        title={t('accounts.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IAccount>
        ellipsis
        dataIndex={['currency', 'code']}
        key="currency_code"
        title={t('accounts.fields.currency')}
      />
      <Table.Column<IAccount>
        ellipsis
        dataIndex={'kind'}
        key="kind"
        title={t('accounts.fields.kind')}
        render={(value) => <Tag>{value}</Tag>}
      />
      <Table.Column<IAccount>
        ellipsis
        dataIndex="balance"
        key="balance"
        width={150}
        title={t('accounts.fields.balance')}
        render={(value, record) => (
          <TextField value={formattedCurrencyAmount(value, 2, record?.currency?.code)} />
        )}
      />
      <Table.Column<IAccount>
        ellipsis
        dataIndex="hold_balance"
        key="hold_balance"
        width={150}
        title={t('accounts.fields.hold_balance')}
        render={(value, record) => (
          <TextField value={formattedCurrencyAmount(value, 2, record?.currency?.code)} />
        )}
      />
      <Table.Column<IAccount>
        ellipsis
        dataIndex="balance_with_holds"
        key="balance_with_holds"
        width={150}
        title={t('accounts.fields.balance_with_holds')}
        render={(value, record) => (
          <TextField value={formattedCurrencyAmount(value, 2, record?.currency?.code)} />
        )}
      />
      <Table.Column<IAccount>
        key="member"
        title={t('accounts.fields.member')}
        render={(_, record) => (
          <Space direction='vertical' size={1}>
            {record?.merchant?.id && (
              <>
                {t('accounts.fields.merchant')}
                <Tag><ShortId value={record?.merchant?.id} /></Tag>
                <Tag color='purple'>{record?.merchant?.name}</Tag>
              </>
            )}
            {record?.team?.id && (
              <>
                {t('accounts.fields.team')}
                <Tag><ShortId value={record?.team?.id} /></Tag>
                <Tag color='geekblue'>{record?.team?.name}</Tag>
              </>
            )}
            {record?.provider?.id && (
              <>
                {t('accounts.fields.provider')}
                <Tag><ShortId value={record?.provider?.id} /></Tag>
                <Tag color='blue'>{record?.provider?.name}</Tag>
              </>
            )}
            {record?.agent?.id && (
              <>
                {t('accounts.fields.agent')}
                <Tag><ShortId value={record?.agent?.id} /></Tag>
                <Tag>{record?.agent?.name}</Tag>
              </>
            )}
          </Space>
        )}
      />
      <Table.Column<IAccount>
        ellipsis
        dataIndex="created_at"
        key="created_at"
        title={t('accounts.fields.created_at')}
        render={(value) => <DateField style={{ fontSize: 12 }} value={value} />}
      />
      <Table.Column<IAccount>
        ellipsis
        dataIndex="updated_at"
        key="updated_at"
        title={t('accounts.fields.updated_at')}
        render={(value) => <DateField style={{ fontSize: 12 }} value={value} />}
      />
    </Table>
  )
}

export default AccountsTable
