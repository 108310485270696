import { IResourceComponentsProps, useTranslate, useList } from '@refinedev/core'
import { Create, useForm } from '@refinedev/antd'
import { Checkbox, Form, Input, Select } from 'antd'

import { IPartner, ICurrency } from 'interfaces'

export const PartnerCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<IPartner>()

  const { data: currenciesData } = useList<ICurrency>({
    resource: 'currencies',
    pagination: {
      current: 1,
      pageSize: 1000,
    },
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
  })
  const currencies = currenciesData?.data ?? []

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" initialValues={{ currency: 'RUB' }}>
        <Form.Item
          label={t('partners.fields.name')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          label={t('partners.fields.currency')}
          name="currency"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            options={currencies.map((currency) => ({
              label: currency.code,
              value: currency.code,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="flexpay"
          valuePropName="checked"
          label={t('partners.fields.flexpay')}
          help={t('partners.form.flexpay.help')}
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Create>
  )
}
