import { LogoutOutlined, SettingOutlined } from '@ant-design/icons'
import { useGetIdentity, useLogout, useTranslate } from '@refinedev/core'
import {
  Avatar,
  Dropdown,
  Layout as AntdLayout,
  MenuProps,
  Space,
  theme,
  Typography,
  Flex,
  Button,
  Grid,
  Switch
} from 'antd'
import { useEffect, useContext } from 'react'
import { Shift } from 'components/Shift'
import { ICurrentUser } from 'interfaces'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { ColorModeContext } from 'contexts/color-mode'
import './styles.css'

import {
  useGetLocale,
  useSetLocale,
} from '@refinedev/core'
import { useTranslation } from 'react-i18next'
import { DownOutlined } from '@ant-design/icons'

const { Text } = Typography
const { useToken } = theme

const ProfileMenu: React.FC<{ user: ICurrentUser }> = ({ user }) => {
  const t = useTranslate()
  const { mutate: mutateLogout } = useLogout()

  const items: MenuProps['items'] = [
    {
      label: <Text>{user.email}</Text>,
      disabled: true,
      key: '0',
    },
    {
      label: <Link to={'/profile'}>{t('components.profile_menu.settings')}</Link>,
      key: '1',
      icon: <SettingOutlined />,
    },
    {
      type: 'divider',
    },
    {
      label: (
        <div onClick={() => mutateLogout()}>{t('components.profile_menu.logout')}</div>
      ),
      key: '2',
      danger: true,
      icon: <LogoutOutlined onClick={() => mutateLogout()} />,
    },
  ]

  return (
    <Dropdown
      menu={{ items }}
      trigger={['hover']}
    >
      <Avatar size="default">
        {user?.email[0]?.toUpperCase()}
      </Avatar>
    </Dropdown>
  )
}

export const Header: React.FC = () => {
  const { token } = useToken()
  const { data: user } = useGetIdentity<ICurrentUser>()

  const partnerDealing = user?.dealings?.find(
    (dealing) => dealing.role.code === 'partner_admin'
  )

  const breakpoint = Grid.useBreakpoint();
  const isMobile = typeof breakpoint.lg === "undefined" ? true : !breakpoint.lg;

  const { i18n } = useTranslation()
  const locale = useGetLocale()
  const changeLanguage = useSetLocale()
  const currentLocale = locale()

  const { mode, setMode } = useContext(ColorModeContext)

  useEffect(() => {
    user?.timezone && dayjs.tz.setDefault(user.timezone)
    // console.log('user.timezone', user?.timezone)
  }, [user])

  const menuItems: MenuProps['items'] = [...(i18n.languages || [])]
    .sort()
    .map((lang: string) => ({
      key: lang,
      onClick: () => changeLanguage(lang),
      icon: (
        <span style={{ marginRight: 8 }}>
          <img width={20} src={`/images/flags/${lang}.svg`} />
        </span>
      ),
      label: lang.toUpperCase(),
    }))

  return (
    <AntdLayout.Header
      style={{
        backgroundColor: token.colorBgElevated,
        height: 'auto',
        paddingTop: '8px',
        paddingRight: isMobile ? 15 : 40,
        paddingBottom: '8px',
      }}
    >
      <Flex
        justify='space-between'
        align='start'
      >
        <Space>{user?.email && !!partnerDealing && <Shift partnerDealing={partnerDealing} />}</Space>
        <Flex
          justify='space-between'
          align='center'
          gap={isMobile ? 0 : 15}
        >
          <Switch
            checkedChildren="🌛"
            unCheckedChildren="🔆"
            onChange={() => setMode(mode === 'light' ? 'dark' : 'light')}
            defaultChecked={mode === 'light'}
          />
          <Dropdown
            menu={{
              items: menuItems,
              selectedKeys: currentLocale ? [currentLocale] : [],
            }}
          >
            <Button type="text">
              <Flex
                align='center'
                justify='space-between'
                gap={5}
              >
                <img width={20} src={`/images/flags/${currentLocale}.svg`} />
                {currentLocale?.toUpperCase()}
                <DownOutlined />
              </Flex>
            </Button>
          </Dropdown>
          {user?.email && <ProfileMenu user={user} />}
        </Flex>
      </Flex>
    </AntdLayout.Header>
  )
}
