import { EyeOutlined } from '@ant-design/icons'
import { Button, Modal, Typography, Timeline, Descriptions, Card, Spin, Tag } from 'antd'
import { useApiUrl, useCustom, useTranslate } from '@refinedev/core'
import { IOperationView, IOperationViewEvent, IProviderData, IAurisInfo, ICallback } from 'interfaces'
import { useState } from 'react'
import DateField from 'components/DateField'
import { formattedAmount, formattedCardNumber, formattedAccountNumber } from 'utils'
import BooleanTag from 'components/BooleanTag'
import { actionColor } from 'utils'

const CardContent: React.FC<{ event: IOperationViewEvent }> = ({ event }) => {
  let content = <></>
  switch (event.kind) {
    case 'operation':
      content = (
        <Descriptions column={1}>
          <Descriptions.Item label={'status'}>{event.data.status}</Descriptions.Item>
        </Descriptions>
      )
      break
    case 'operations.manually_approve_with_sms':
      content = (
        <Descriptions column={1}>
          <Descriptions.Item label={'approved_amount'}>
            {formattedAmount(event.data.approved_amount)}
          </Descriptions.Item>
          <Descriptions.Item label={'user'}>{event.data.user.email}</Descriptions.Item>
        </Descriptions>
      )
      break
    case 'operations.update_payment_requisite':
      content = (
        <Descriptions column={1}>
          <Descriptions.Item label={'user'}>{event.data.user.email}</Descriptions.Item>
          <Descriptions.Item label={'card_number'}>
            {formattedCardNumber(event.data.card_number)}
          </Descriptions.Item>
          <Descriptions.Item label={'account'}>
            {formattedAccountNumber(event.data.account)}
          </Descriptions.Item>
        </Descriptions>
      )
      break
    case 'sms':
      content = (
        <Descriptions column={1}>
          <Descriptions.Item label={'body'}>{event.data.body}</Descriptions.Item>
          <Descriptions.Item label={'phone'}>{event.data.phone}</Descriptions.Item>
          <Descriptions.Item label={'from_push'}>
            {event.data.from_push}
          </Descriptions.Item>
          <Descriptions.Item label={'from_livecheck'}>
            {event.data.from_livecheck}
          </Descriptions.Item>
          <Descriptions.Item label={'from_fetch_all'}>
            {event.data.from_fetch_all}
          </Descriptions.Item>
          <Descriptions.Item label={'app_version'}>
            {event.data.app_version}
          </Descriptions.Item>
        </Descriptions>
      )
      break
    case 'feed_item':
      content = (
        <Descriptions column={1}>
          <Descriptions.Item label={'kind'}>{event.data.kind}</Descriptions.Item>
          <Descriptions.Item label={'amount'}>
            {formattedAmount(event.data.amount)}
          </Descriptions.Item>
          <Descriptions.Item label={'commission'}>
            {formattedAmount(event.data.commission)}
          </Descriptions.Item>
          <Descriptions.Item label={'balance'}>
            {formattedAmount(event.data.balance)}
          </Descriptions.Item>
          <Descriptions.Item label={'shift'}>
            {`${event.data.shift.partner.name} (№${event.data.shift.number})`}
          </Descriptions.Item>
        </Descriptions>
      )
      break
    case 'payment_requisite.temporary_unavailable':
      content = (
        <Descriptions column={1}>
          <Descriptions.Item label={'amount'}>
            {formattedAmount(event.data.amount)}
          </Descriptions.Item>
          <Descriptions.Item label={'gateway_id'}>
            {event.data.gateway_id}
          </Descriptions.Item>
        </Descriptions>
      )
      break
    case 'payment_requisite_block':
      content = (
        <Descriptions column={1}>
          <Descriptions.Item label={'card_number'}>
            {formattedCardNumber(event.data.card_number)}
          </Descriptions.Item>
          <Descriptions.Item label={'account'}>
            {formattedAccountNumber(event.data.account)}
          </Descriptions.Item>
          <Descriptions.Item label={'block_from'}>
            {<DateField value={event.data.block_from} format="DD.MM.YYYY, HH:mm:ss" />}
          </Descriptions.Item>
          <Descriptions.Item label={'block_to'}>
            {<DateField value={event.data.block_to} format="DD.MM.YYYY, HH:mm:ss" />}
          </Descriptions.Item>
          <Descriptions.Item label={'flexpay'}>
            <BooleanTag value={event.data.flexpay} />
          </Descriptions.Item>
        </Descriptions>
      )
      break
    case 'operations.create':
      content = (
        <Descriptions column={1}>
          <Descriptions.Item label={'user'}>{event.data.user.email}</Descriptions.Item>
        </Descriptions>
      )
      break
    case 'operations.create_payout_without_payment_requisite':
      content = (
        <Descriptions column={1}>
          <Descriptions.Item label={'user'}>{event.data?.user?.email}</Descriptions.Item>
        </Descriptions>
      )
      break
    case 'operations.payment_requisites_assigned':
      content = (
        <Descriptions column={1}>
          <Descriptions.Item label={'partner'}>{event.data?.partner_name}</Descriptions.Item>
        </Descriptions>
      )
      break
  }

  return content
}

const Auris: React.FC<{ auris?: IAurisInfo }> = ({ auris }) => {
  return (
    <>
      {auris?.payment &&
        <>
          <Typography.Title level={5}>Payment</Typography.Title>
          <Tag
            color={auris?.payment?.response?.error ? 'red' : 'green'}
            style={{ width: '100%' }}
          >
            <pre>{JSON.stringify(auris?.payment, null, 2)}</pre>
          </Tag>
        </>
      }
      {auris?.withdraw &&
        <>
          <Typography.Title level={5}>Withdraw</Typography.Title>
          <Tag
            color={auris?.withdraw?.response?.error ? 'red' : 'green'}
            style={{ width: '100%' }}
          >
            <pre>{JSON.stringify(auris?.withdraw, null, 2)}</pre>
          </Tag>
        </>
      }
      {auris?.acquire &&
        <>
          <Typography.Title level={5}>Acquire</Typography.Title>
          <Tag
            color={auris?.acquire?.response?.error ? 'red' : 'green'}
            style={{ width: '100%' }}
          >
            <pre>{JSON.stringify(auris?.acquire, null, 2)}</pre>
          </Tag>
        </>
      }
      {auris?.statuses?.length ? (
        <>
          <Typography.Title level={4}>Statuses</Typography.Title>
          {auris?.statuses?.map((status) => (
            <Tag
              color={'geekblue'}
              style={{ width: '100%' }}
            >
              <pre>{JSON.stringify(status, null, 2)}</pre>
            </Tag>
          ))}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

const Callbacks: React.FC<{ callbacks?: ICallback[] }> = ({ callbacks }) => {
  return (
    <>
      {callbacks?.map((callback) => (
        <Tag
          color={'geekblue'}
          style={{ width: '100%' }}
        >
          <pre>{JSON.stringify(callback, null, 2)}</pre>
        </Tag>
      ))}
    </>
  )
}

const Info: React.FC<{ id: string }> = ({ id }) => {
  const apiUrl = useApiUrl()
  const t = useTranslate()

  const { data: remoteData, isLoading } = useCustom<IOperationView>({
    url: `${apiUrl}/operations/${id}/view`,
    method: 'get',
  })
  const operation = remoteData?.data.operation

  const { data: providerData, isLoading: isFetching } = useCustom<IProviderData>({
    url: `${apiUrl}/operations/${id}/provider_data`,
    method: 'get',
  })
  const provider_data = providerData?.data

  return (operation && !isLoading && !isFetching) ? (
    <>
      <Typography.Title level={2}>История</Typography.Title>
      <Timeline
        items={
          remoteData?.data.events.map((event) => ({
            dot: <></>,
            children: (
              <Card
                style={{ marginLeft: -30 }}
                title={<Tag color={actionColor(event?.kind)}>{t('events.action_codes.' + event.kind)}</Tag>}
                extra={
                  <DateField
                    value={event.timestamp}
                    format="DD.MM.YYYY, HH:mm:ss"
                    style={{ fontSize: 12 }}
                  />
                }
                size="small"
              >
                <CardContent event={event} />
              </Card>
            ),
          })) || []
        }
      />

      {(provider_data?.auris?.payment ||
        provider_data?.auris?.withdraw ||
        provider_data?.auris?.acquire ||
        provider_data?.auris?.statuses?.length) ? (
        <>
          <Typography.Title level={3}>Auris</Typography.Title>
          <Auris auris={provider_data?.auris} />
        </>
      ) : (
        <></>
      )}

      {provider_data?.callbacks?.length ? (
        <>
          <Typography.Title level={4}>Callbacks</Typography.Title>
          <Callbacks callbacks={provider_data?.callbacks} />
        </>
      ) : (
        <></>
      )}
    </>
  ) : (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
      <Spin tip="Loading" size="large" />
    </div>
  )
}

export const OperationView: React.FC<{ id: string }> = ({ id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Button icon={<EyeOutlined />} size="small" onClick={showModal} />
      <Modal open={isModalOpen} onCancel={closeModal} footer={null} destroyOnClose={true}>
        <Info id={id}></Info>
      </Modal>
    </>
  )
}
