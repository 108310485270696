import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Table, TableProps, Tag, Space, Descriptions } from 'antd'
import { IEvent } from 'interfaces'
import { ShortId } from 'components/ShortId'
import DateField from 'components/DateField'
import { CopyText } from 'components/CopyText'
import { useState } from 'react'
import { actionColor, firstCharColor } from 'utils'

const EventsTable: React.FC<
  IResourceComponentsProps & { tableProps: TableProps<IEvent> }
> = ({ tableProps }) => {
  const t = useTranslate()

  const [expandedKey, setExpandedKey] = useState(String)
  const onExpand = (_: any, record: IEvent) => {
    expandedKey === record.id ? setExpandedKey('') : setExpandedKey(record.id)
  }

  return (
    <Table
      {...tableProps}
      size="small"
      rowKey="id"
      expandable={{
        expandedRowRender: (event) => (
          <Descriptions
            layout='vertical'
            bordered
          >
            <Descriptions.Item label={t('events.fields.payload')}>
              <Tag
                color={event?.payload?.errors ? 'red' : 'geekblue'}
              >
                <pre>{JSON.stringify(event?.payload, null, 2)}</pre>
              </Tag>
            </Descriptions.Item>
          </Descriptions>
        ),
        onExpand,
        expandedRowKeys: [expandedKey]
      }}
      pagination={
        tableProps.pagination && (tableProps.pagination?.total ?? 0) > 10
          ? {
            ...tableProps.pagination,
            position: ['topLeft'],
            size: 'small',
          }
          : false
      }
    >
      <Table.Column<IEvent>
        ellipsis
        dataIndex="created_at"
        key="created_at"
        title={t('events.fields.created_at')}
        render={(value) => <DateField style={{ fontSize: 12 }} value={value} />}
      />
      <Table.Column<IEvent>
        dataIndex="action"
        key="action"
        title={t('events.fields.action')}
        render={(value) =>
          <Space
          // direction='vertical'
          >
            <Tag color={actionColor(value?.code)}>{value?.code}</Tag>
            {t('events.action_codes.' + value?.code) !== 'events.action_codes.' + value?.code &&
              <Tag color={actionColor(value?.code)}>{t('events.action_codes.' + value?.code)}</Tag>
            }
          </Space >
        }
      />
      <Table.Column<IEvent>
        dataIndex="merchant"
        key="merchant"
        title={t('events.fields.merchant')}
        render={(value) =>
          <Space
          // direction='vertical'
          >
            <Tag><ShortId value={value?.id} /></Tag>
            {value?.name && <Tag color={firstCharColor(value?.name)}>{value?.name}</Tag>}
          </Space>
        }
      />
      <Table.Column<IEvent>
        ellipsis
        dataIndex="operation_id"
        key="operation_id"
        title={t('events.fields.operation_id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IEvent>
        ellipsis
        dataIndex="dispute_id"
        key="dispute_id"
        title={t('events.fields.dispute_id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IEvent>
        dataIndex="user"
        key="user"
        title={t('events.fields.user')}
        render={(value) =>
          <Space
          // direction='vertical'
          >
            <Tag><ShortId value={value?.id} /></Tag>
            {value?.email && <Tag color='blue'><CopyText value={value?.email} style={{ fontSize: 12 }} /></Tag>}
          </Space>
        }
      />
      <Table.Column<IEvent>
        ellipsis
        dataIndex="id"
        key="id"
        title={t('events.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
    </Table>
  )
}

export default EventsTable
