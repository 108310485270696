import React, { useState } from 'react'
import { useApiUrl, useCustom, useTranslate } from "@refinedev/core"
import { useQueryClient } from "@tanstack/react-query"
import { IDealing, IShift } from 'interfaces'
import { Current } from './current'
import { useForm } from "@refinedev/antd";
import { Button, Modal } from "antd";
import { PlayCircleOutlined } from '@ant-design/icons'
import { NewShift } from './new'

export const Shift: React.FC<{ partnerDealing: IDealing }> = ({ partnerDealing }) => {
  const t = useTranslate()
  const apiUrl = useApiUrl()
  const queryClient = useQueryClient()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { formProps, saveButtonProps } = useForm<IShift>({
    action: 'create',
    resource: 'shifts',
    redirect: false,
    onMutationSuccess: () => {
      queryClient.invalidateQueries(['shifts', 'current'])
      closeModal()
    },
  })

  const showModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const { data: currentShiftData, isLoading } = useCustom<IShift>({
    url: `${apiUrl}/shifts/current`,
    method: 'get',
    config: {
      query: {
        partner_id: partnerDealing.partner_id,
      },
    },
    queryOptions: {
      queryKey: ['shifts', 'current'],
    },
  })

  if (!isLoading && partnerDealing.partner_id !== undefined) {
    const shift = currentShiftData && currentShiftData.data

    if (shift?.id) {
      return <Current shift={shift} />
    } else {
      return (
        <div>
          <Button icon={<PlayCircleOutlined />} onClick={showModal}>
            {t('components.shift.start_shift')}
          </Button>
          <Modal
            destroyOnClose={true}
            title={t('shifts.titles.create')}
            open={isModalOpen}
            onCancel={closeModal}
            footer={null}
          >
            <NewShift
              formProps={formProps}
              saveButtonProps={saveButtonProps}
              partner_id={partnerDealing.partner_id}
            />
          </Modal>
        </div>
      )
    }
  } else {
    return null
  }
}
