import { useInvalidate, useTranslate, useCan, useList, useOne } from '@refinedev/core'
import { useForm, } from '@refinedev/antd'
import { Select, Button, Modal, Form, InputNumber, Checkbox, Input, } from 'antd'
import { IGateway, IPaymentCascade, IMerchant } from 'interfaces'
import { useState } from 'react'
import { gatewayOptions } from 'components/GatewayOptions'

const EditPaymentCascadeButton: React.FC<{
  id?: string
}> = ({
  id,
}) => {
    const t = useTranslate()
    const invalidate = useInvalidate()
    const [isModalOpen, setIsModalOpen] = useState(false)

    const { data: canCreatePaymentCascade } = useCan({
      resource: 'payment_cascades',
      action: 'create',
    })

    const { formProps, saveButtonProps, form } = useForm<IPaymentCascade>({
      resource: 'payment_cascades',
      action: 'edit',
      id,
      redirect: false,
      onMutationSuccess: () => {
        invalidate({
          resource: 'payment_cascades',
          invalidates: ['list'],
        })
        closeModal()
      },
    })

    const showModal = () => {
      invalidate({
        resource: 'payment_cascades',
        invalidates: ['detail'],
        id,
      })
      setIsModalOpen(true)
    }

    const closeModal = () => {
      setIsModalOpen(false)
    }

    const kind = Form.useWatch('kind', form)
    const merchant_id = Form.useWatch('merchant_id', form)

    const { data: merchantData } = useOne<IMerchant>({
      resource: 'merchants',
      //@ts-ignore
      id: merchant_id,
      queryOptions: {
        enabled: merchant_id != undefined,
      },
    })

    const { data: gatewaysData } = useList<IGateway>({
      resource: 'gateways',
      filters: [
        {
          field: 'compact',
          operator: 'eq',
          value: true,
        },
        {
          field: 'currency',
          operator: 'eq',
          value: merchantData?.data?.currency,
        },
        {
          field: 'direction',
          operator: 'eq',
          value: kind == 'payout' ? 'withdraw' : 'deposit',
        },
        {
          field: 'payment_type',
          operator: 'eq',
          value: ['payout', 'card', 'sbp', 'sbpqr', 'account', 'ecom', 'iban', 'qr', 'url', 'cvu', 'va', 'phone'],
        },
      ],
      pagination: {
        current: 1,
        pageSize: 1000,
      },
    })
    const gateways = gatewaysData?.data ?? []

    return (
      <>
        {canCreatePaymentCascade?.can &&
          <Button
            size="small"
            onClick={() => {
              showModal()
            }}
            style={{
              width: 'fit-content'
            }}
          >
            {t('components.add_payment_cascade.buttons.edit')}
          </Button>
        }
        <Modal
          open={isModalOpen}
          onCancel={closeModal}
          title={t('components.add_payment_cascade.titles.edit')}
          footer={null}
        >
          <Form {...formProps} layout="vertical">
            <Form.Item
              hidden
              initialValue={formProps?.initialValues?.merchant?.id}
              name="merchant_id"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="payment_system"
              rules={[{ required: true, message: '' },]}
            >
              <Select
                disabled
                options={[
                  {
                    label: t('payment_cascades.payment_system.spacepayments'),
                    value: 'spacepayments',
                  },
                  {
                    label: t('payment_cascades.payment_system.auris'),
                    value: 'auris',
                  }
                ]}
              />
            </Form.Item>
            <Form.Item
              name="kind"
              rules={[{ required: true, message: '' },]}
            >
              <Select
                disabled
                options={[
                  {
                    label: t('payment_cascades.kind.income'),
                    value: 'income',
                  },
                  {
                    label: t('payment_cascades.kind.payout'),
                    value: 'payout',
                  }
                ]}
              />
            </Form.Item>
            <Form.Item
              label={t('payment_cascades.form.traffic_percentage.label')}
              name="traffic_percentage"
              rules={[{ required: true, message: '' },]}
            >
              <InputNumber min={0} max={100} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="all_gateways"
              valuePropName="checked"
              label={t('payment_cascades.fields.all_gateways')}
            >
              <Checkbox />
            </Form.Item>
            <Form.Item
              name="gateway_id"
              label={t('payment_cascades.fields.gateway')}
              initialValue={formProps?.initialValues?.gateway?.id}
            >
              <Select
                allowClear
                showSearch
                options={gatewayOptions(gateways)}
                placeholder={t('agreements.form.gateway.placeholder')}
                filterOption={(input, option: any) =>
                  (option?.desc ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
            <Button {...saveButtonProps}>
              {t('partners.buttons.update_merchant')}
            </Button>
          </Form>
        </Modal>
      </>
    )
  }

export default EditPaymentCascadeButton
