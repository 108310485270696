import React from 'react'
import { IResourceComponentsProps } from '@refinedev/core'
import { List, useTable } from '@refinedev/antd'
import { IProvider } from 'interfaces'
import ProvidersTable from './list_table'

export const ProvidersList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<IProvider>()

  return (
    <List>
      <ProvidersTable tableProps={tableProps}></ProvidersTable>
    </List>
  )
}
