import { IResourceComponentsProps, useTranslate } from '@refinedev/core'

import { Table, TableProps, Grid } from 'antd'
import { ICurrency } from 'interfaces'
import DateField from 'components/DateField'
import { ShortId } from 'components/ShortId'

const CurrenciesTable: React.FC<
  IResourceComponentsProps & {
    tableProps: TableProps<ICurrency>
  }
> = ({ tableProps }) => {
  const t = useTranslate()

  const breakpoint = Grid.useBreakpoint();
  const isMobile = typeof breakpoint.lg === "undefined" ? true : !breakpoint.lg;

  return (
    <Table
      {...tableProps}
      size="small"
      rowKey="id"
      pagination={{
        ...tableProps.pagination,
        position: ['bottomLeft'],
        size: 'small',
      }}
    >
      <Table.Column<ICurrency>
        width={150}
        dataIndex="id"
        ellipsis
        key="id"
        title={t('currencies.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<ICurrency>
        dataIndex="code"
        ellipsis
        key="code"
        title={t('currencies.fields.code')}
      />
    </Table>
  )
}

export default CurrenciesTable
