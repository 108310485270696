import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Switch, Typography, Flex } from 'antd'
import { useApiUrl, useCustomMutation, useTranslate } from '@refinedev/core'
import { GenericResponse, IShift } from 'interfaces'

export const PayoutControl: React.FC<{ shift: IShift }> = ({ shift }) => {
  const t = useTranslate()
  const queryClient = useQueryClient()
  const { mutate: updateResource } = useCustomMutation<GenericResponse>()
  const apiUrl = useApiUrl()
  const [payoutsEnabled, setPayoutsEnabled] = useState<boolean>(
    shift.partner.payouts_enabled
  )
  const [loading, setLoading] = useState(false)

  const togglePayoutsEnabled = (mode: boolean) => {
    setLoading(true)
    setPayoutsEnabled(mode)

    const action = mode ? 'resume' : 'pause'

    setTimeout(() => {
      updateResource(
        {
          url: `${apiUrl}/shifts/${shift.id}/${action}`,
          method: 'post',
          values: {
            mode: 'payouts',
          },
          successNotification: (data, values) => {
            return {
              message: t(`shifts.notifications.payouts_enabled.${mode}.message`),
              description: t(`shifts.notifications.payouts_enabled.${mode}.description`),
              type: 'success',
            }
          },
        },
        {
          onError: (error, variables, context) => { },
          onSuccess: (data, variables, context) => {
            setLoading(false)
            queryClient.invalidateQueries(['shifts', 'current'])
          },
        }
      )
    }, 300)
  }

  return (
    <Flex
      align='center'
    >
      <Switch
        loading={loading}
        size="small"
        onChange={() => {
          payoutsEnabled ? togglePayoutsEnabled(false) : togglePayoutsEnabled(true)
        }}
        defaultChecked={payoutsEnabled}
      />
      <Typography.Text style={{ marginLeft: 8 }}>
        {t('shifts.form.toggle_payouts_enabled')}
      </Typography.Text>
    </Flex>
  )
}
