import { useInvalidate, useTranslate, useCan, useList } from '@refinedev/core'
import { useModalForm } from '@refinedev/antd'
import { Select, Button, Modal, Form, Input, InputNumber, SelectProps, Space, Tag } from 'antd'
import { IAccount, IOperation } from 'interfaces'
import { formattedCurrencyAmount, formattedAmount } from 'utils'
import { AccountOperationKind } from 'components/AccountOperationKind'
import { nanoid } from 'nanoid'

const AddAccountOperationButton: React.FC<{
  merchant_id?: string
  agent_id?: string
  team_id?: string
  provider_id?: string
}> = ({
  merchant_id,
  agent_id,
  team_id,
  provider_id
}) => {
    const t = useTranslate()
    const invalidate = useInvalidate()

    const { data: canCreateAccountOperation } = useCan({
      resource: 'account_operations',
      action: 'create',
    })

    const {
      modalProps: createModalProps,
      formProps: createFormProps,
      show: showModal,
    } = useModalForm<IOperation>({
      resource: 'account_operations',
      action: 'create',
      redirect: 'list',
      onMutationSuccess: () => {
        invalidate({
          resource: 'account_operations',
          invalidates: ['list'],
        })
      },
    })

    let filter_field = '';
    let filter_value = '';
    if (merchant_id) {
      filter_field = 'merchant_id'
      filter_value = merchant_id
    } else if (agent_id) {
      filter_field = 'agent_id'
      filter_value = agent_id
    } else if (team_id) {
      filter_field = 'team_id'
      filter_value = team_id
    } else if (provider_id) {
      filter_field = 'provider_id'
      filter_value = provider_id
    }

    const { data: accountsData } = useList<IAccount>({
      resource: 'accounts',
      filters: [
        {
          field: filter_field,
          operator: 'eq',
          value: filter_value,
        },
      ],
      pagination: {
        current: 1,
        pageSize: 1000,
      },
    })

    const accountOptions: SelectProps['options'] = accountsData?.data.map((account) => ({
      label: (
        <Space size={1}>
          <Tag>{account?.currency?.code}</Tag>
          <Tag>{account?.kind}</Tag>
          {formattedCurrencyAmount(account?.balance, 2, account?.currency?.code)}&nbsp;
          <Tag>{t('accounts.fields.hold_balance')} {formattedAmount(account?.hold_balance)}</Tag>
          <Tag>{t('accounts.fields.balance_with_holds')} {formattedAmount(account?.balance_with_holds)}</Tag>
        </Space>
      ),
      desc: (account?.currency?.code ?? ''),
      value: account.id,
    }))

    return (
      <>
        {canCreateAccountOperation?.can &&
          <Button
            type='primary'
            onClick={() => {
              showModal()
            }}
            style={{
              width: 'fit-content'
            }}
          >
            {t('components.add_account_operations.buttons.create')}
          </Button>
        }
        <Modal
          {...createModalProps}
          title={t('components.add_account_operations.titles.create')}
        >
          <Form {...createFormProps} layout="vertical">
            <Form.Item name="idempotency_key" initialValue={nanoid(32)} hidden>
              <Input />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: '' },]}
              label={t('components.add_account_operations.titles.kind')}
              name="kind"
            >
              <Select
                options={[
                  {
                    label: <AccountOperationKind kind={'deposit'} />,
                    value: 'deposit',
                  },
                  {
                    label: <AccountOperationKind kind={'withdraw'} />,
                    value: 'withdraw',
                  }
                ]}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: '' },]}
              label={t('components.add_account_operations.titles.account')}
              name="account_id"
            >
              <Select
                options={accountOptions}
                filterOption={(input, option: any) =>
                  (option?.desc ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: '' },]}
              label={t('components.add_account_operations.titles.amount')}
              name="amount"
            >
              <InputNumber min={0} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              label={t('components.add_account_operations.titles.comment')}
              name="comment"
            >
              <Input.TextArea rows={6} allowClear />
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }

export default AddAccountOperationButton
