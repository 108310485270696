import React from 'react'
import { gatewayDirections } from 'interfaces'
import { Tag } from 'antd'

export const GatewayDirection: React.FC<{ direction: gatewayDirections }> = ({ direction }) => {

  const GatewayDirectionColorMap = {
    deposit: 'green',
    withdraw: 'red'
  }

  return (
    <Tag
      color={GatewayDirectionColorMap[direction]}
      style={{ fontSize: 14 }}
    >
      {direction}
    </Tag>
  )
}
