import {
  getDefaultFilter,
  CrudFilters,
  IResourceComponentsProps,
  useTranslate,
  useList,
  useCan
} from '@refinedev/core'
import { List, TextField, TagField, useTable, EditButton, CreateButton } from '@refinedev/antd'
import { Table, Form, Segmented, Input, Tag, Grid } from 'antd'
import { IGateway, ICurrency } from 'interfaces'
import { ShortId } from 'components/ShortId'
import { GatewayType } from 'components/GatewayType'
import { SearchOutlined } from '@ant-design/icons'
import { GatewayDirection } from 'components/GatewayDirection'
import { GatewayMethod } from 'components/GatewayMethod'
import { gatewayDirectionOptions, gatewayPaymentMethodOptions, gatewayPaymentTypeOptions, generalStatusOptions } from 'utils'

export const GatewaysList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const breakpoint = Grid.useBreakpoint();
  const isMobile = typeof breakpoint.lg === "undefined" ? true : !breakpoint.lg;

  const { data: canEditGateway } = useCan({
    resource: 'gateways',
    action: 'edit',
  })

  const { data: canFilterGateway } = useCan({
    resource: 'gateways',
    action: 'filter',
  })

  const { tableProps, searchFormProps, filters } = useTable<IGateway>({
    onSearch: (params) => {
      // @ts-ignore
      const { payment_type, payment_method, direction, status, currency, id, search } = params
      const gatewaysFilters: CrudFilters = []

      gatewaysFilters.push({
        field: 'payment_type',
        operator: 'eq',
        value: payment_type ? payment_type : null,
      })

      gatewaysFilters.push({
        field: 'payment_method',
        operator: 'eq',
        value: payment_method ? payment_method : null,
      })

      gatewaysFilters.push({
        field: 'direction',
        operator: 'eq',
        value: direction ? direction : null,
      })

      gatewaysFilters.push({
        field: 'status',
        operator: 'eq',
        value: status ? status : null,
      })


      gatewaysFilters.push({
        field: 'currency',
        operator: 'eq',
        value: currency ? currency : null,
      })

      gatewaysFilters.push({
        field: 'id',
        operator: 'eq',
        value: id ? id : null,
      })

      gatewaysFilters.push({
        field: 'search',
        operator: 'eq',
        value: search ? search : null,
      })

      return gatewaysFilters
    },

    pagination: {
      pageSize: 20,
    },
  })

  const { data: currenciesData } = useList<ICurrency>({
    resource: 'currencies',
    pagination: {
      current: 1,
      pageSize: 1000,
    },
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
  })
  const currencies = currenciesData?.data ?? []

  var timerId: any
  const debounceFunction = (func: any, delay: number) => {
    clearTimeout(timerId)
    timerId = setTimeout(func, delay)
  }
  const onValueChange = () => {
    debounceFunction(() => {
      searchFormProps.form?.submit()
    }, 400)
  }

  return (
    <List
      headerButtons={<CreateButton />}
    >
      <Form
        {...searchFormProps}
        layout="horizontal"
        onValuesChange={onValueChange}
        initialValues={{
          payment_type: getDefaultFilter('payment_type', filters),
          payment_method: getDefaultFilter('payment_method', filters),
          direction: getDefaultFilter('direction', filters),
          status: getDefaultFilter('status', filters),
          currency: getDefaultFilter('currency', filters),
          id: getDefaultFilter('id', filters),
          search: getDefaultFilter('search', filters),
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: 10,
            marginBottom: 10,
          }}>
          <Form.Item
            name="id"
            style={{
              flexGrow: 1,
              marginBottom: 5,
            }}
          >
            <Input
              placeholder={t('gateways.filter.id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="search"
            style={{
              flexGrow: 1,
              marginBottom: 5,
            }}
          >
            <Input
              placeholder={t('gateways.filter.search.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
        </div>
        {canFilterGateway?.can &&
          <>
            <div
              style={{
                overflow: 'auto',
              }}
            >
              <Form.Item name="status" style={{ marginBottom: 5, }}>
                <Segmented
                  options={[
                    { label: 'ALL', value: '' },
                    ...generalStatusOptions.map((option) => ({
                      label: option.label?.toLocaleUpperCase(),
                      value: option.value,
                    }))
                  ]}
                />
              </Form.Item>
            </div>
            <div
              style={{
                overflow: 'auto',
              }}
            >
              <Form.Item name="direction" style={{ marginBottom: 5, }}>
                <Segmented
                  options={[
                    { label: 'ALL', value: '' },
                    ...gatewayDirectionOptions.map((option) => ({
                      label: option.label?.toLocaleUpperCase(),
                      value: option.value,
                    }))
                  ]}
                />
              </Form.Item>
            </div>
            <div
              style={{
                overflow: 'auto',
              }}
            >
              <Form.Item name="payment_method" style={{ marginBottom: 5, }}>
                <Segmented
                  options={[
                    { label: 'ALL', value: '' },
                    ...gatewayPaymentMethodOptions.map((option) => ({
                      label: option.label?.toLocaleUpperCase(),
                      value: option.value,
                    }))
                  ]}
                />
              </Form.Item>
            </div>
            <div
              style={{
                overflow: 'auto',
              }}
            >
              <Form.Item name="payment_type" style={{ marginBottom: 5, }}>
                <Segmented
                  options={[
                    { label: 'ALL', value: '' },
                    ...gatewayPaymentTypeOptions.map((option) => ({
                      label: option.label?.toLocaleUpperCase(),
                      value: option.value,
                    }))
                  ]}
                />
              </Form.Item>
            </div>
            <div
              style={{
                overflow: 'auto'
              }}
            >
              <Form.Item name="currency">
                <Segmented
                  options={[
                    ...[{ label: 'ALL', value: '' },],
                    ...currencies.map((currency) => ({
                      label: currency.code,
                      value: currency.code,
                    }))
                  ]}
                />
              </Form.Item>
            </div>
          </>
        }
      </Form>
      <Table
        {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          position: ['bottomLeft'],
          size: 'small',
        }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          width={150}
          ellipsis
          title={t('gateways.fields.id')}
          render={(value) => <ShortId value={value} />}
        />
        <Table.Column
          dataIndex="direction"
          key="direction"
          width={100}
          ellipsis
          title={t('gateways.fields.direction')}
          render={(value) => <GatewayDirection direction={value} />}
        />
        <Table.Column
          dataIndex="payment_method"
          key="payment_method"
          width={100}
          ellipsis
          title={t('gateways.fields.payment_method')}
          render={(value) => <GatewayMethod method={value} />}
        />
        <Table.Column
          dataIndex="payment_type"
          key="payment_type"
          width={100}
          ellipsis
          title={t('gateways.fields.payment_type')}
          render={(value) => <GatewayType type={value} />}
        />
        <Table.Column
          dataIndex="currency"
          key="currency"
          width={100}
          title={t('gateways.fields.currency')}
        />
        <Table.Column
          dataIndex="name"
          key="name"
          ellipsis
          title={t('gateways.fields.name')}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="status"
          key="status"
          title={t('gateways.fields.status')}
          render={(value) => <TagField color={value === 'active' ? 'green' : 'red'} value={value} />}
        />
        <Table.Column
          dataIndex="description"
          key="description"
          title={t('gateways.fields.description')}
        />
        {canEditGateway?.can &&
          <Table.Column
            fixed="right"
            align="center"
            key="actions"
            render={(_, record) =>
              <EditButton
                resource="gateways"
                hideText={isMobile}
                size="small"
                recordItemId={record.id}
              />
            }
          />
        }
      </Table>
    </List>
  )
}
