import {
  CrudFilters, getDefaultFilter,
  IResourceComponentsProps, useApiUrl,
  useCustomMutation,
  useInvalidate,
  usePermissions,
  useTranslate,
  useList
} from '@refinedev/core'
import { OrdersTable } from './OrdersTable'
import { GenericResponse, IGateway, IOrder } from '../../interfaces'
import { OperationKindFilter } from '../../components/OperationKindFilter'
import { List, useTable } from '@refinedev/antd'
import { Form, Select } from 'antd'
import React from 'react'
import { gatewayOptions } from 'components/GatewayOptions'

import './styles.css'

export const OrderList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const apiUrl = useApiUrl()
  const invalidate = useInvalidate()
  const { mutate } = useCustomMutation<GenericResponse>()

  const { tableProps, searchFormProps, filters } = useTable<IOrder>({
    resource: 'trade_orders',
    onSearch: (params: any) => {
      const filters: CrudFilters = []
      const {
        gateway_id,
        kind,
      } = params

      filters.push({
        field: 'gateway_id',
        operator: 'eq',
        value: gateway_id,
      })

      filters.push({
        field: 'kind',
        operator: 'eq',
        value: kind,
      })

      return filters
    },
    pagination: {
      pageSize: 20,
    },
  })

  const invalidateTradeOrders = () => {
    invalidate({
      resource: 'trade_orders',
      invalidates: ['list'],
    })
  }

  const onCancel = (id: string) => {
    mutate(
      {
        url: `${apiUrl}/trade_orders/${id}/cancel`,
        method: 'post',
        values: {},
      },
      {
        onSuccess: () => {
          invalidateTradeOrders()
        },
      },
    )
  }

  const { data } = usePermissions<string>()

  const { data: gatewaysData } = useList<IGateway>({
    resource: 'gateways',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
      {
        field: 'payment_type',
        operator: 'eq',
        value: ['payout', 'card', 'sbp', 'sbpqr', 'account', 'ecom', 'iban', 'qr', 'url', 'cvu', 'va', 'phone'],
      },
    ],
    pagination: {
      current: 1,
      pageSize: 1000,
    },
  })
  const gateways = gatewaysData?.data ?? []

  return (
    // todo проверить корректность роли
    <List
      title={t(`trade_orders.titles.list`)}
      canCreate={data?.includes('partner_admin')}
    >
      <Form
        {...searchFormProps}
        layout="horizontal"
        size={'middle'}
        onValuesChange={() => {
          searchFormProps.form?.submit()
        }}
        initialValues={{
          gateway_id: getDefaultFilter('gateway_id', filters),
          kind: getDefaultFilter('kind', filters),
        }}
      >
        <div className={'order-list-filters'}>
          <Form.Item name="gateway_id" className={'order-list-filters_gateway'}>
            <Select
              allowClear
              showSearch
              options={gatewayOptions(gateways)}
              placeholder={t('agreements.form.gateway.placeholder')}
              filterOption={(input, option: any) =>
                (option?.desc ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item name="kind">
            <OperationKindFilter
              operation_kinds={['payout', 'income']}
              default_operation_kind={'payout'}
            />
          </Form.Item>
        </div>
      </Form>

      <OrdersTable tableProps={tableProps} onCancel={onCancel} />

      {/* <h2>{t(`trade_orders.titles.my_orders`)}</h2> */}
    </List>
  )
}