import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Edit, ListButton, useForm } from '@refinedev/antd'
import { Form, Input, InputNumber, } from 'antd'
import { IAccountOperation } from 'interfaces'

export const AccountOperationEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<IAccountOperation>({
    action: 'edit',
    redirect: 'show',
  })

  return (
    <Edit
      headerButtons={<ListButton />}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t('account_operations.fields.amount')}
          name="amount"
        >
          <InputNumber min={0} style={{ fontSize: 24 }} />
        </Form.Item>
        <Form.Item
          label={t('account_operations.fields.comment')}
          name="comment"
        >
          <Input.TextArea rows={10} />
        </Form.Item>
      </Form>
    </Edit>
  )
}
