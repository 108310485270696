import React from 'react'
import { IResourceComponentsProps, useShow, useTranslate } from '@refinedev/core'
import { Show } from '@refinedev/antd'
import { Descriptions, Typography, Tag, Space } from 'antd'
import { IPaymentCascade } from 'interfaces'
import BooleanTag from 'components/BooleanTag'
import { GatewayType } from 'components/GatewayType'
import { GatewayDirection } from 'components/GatewayDirection'
import { GatewayMethod } from 'components/GatewayMethod'

const { Text } = Typography

export const PaymentCascadeShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { queryResult } = useShow<IPaymentCascade>()
  const { data, isLoading } = queryResult
  const record = data?.data

  return (
    <>
      <Show isLoading={isLoading} title={record?.payment_system || ''} canEdit={true}>
        <Descriptions
          column={1}
          bordered
          style={{ maxWidth: 800, }}
        >
          <Descriptions.Item label={t('payment_cascades.fields.payment_system')}>
            <Text style={{ fontWeight: 'bold', }}>{t('payment_cascades.payment_system.' + record?.payment_system)}</Text>
          </Descriptions.Item>
          <Descriptions.Item label={t('payment_cascades.fields.kind')}>
            <Tag color={record?.kind === 'income' ? 'green' : 'red'}>{t('payment_cascades.kind.' + record?.kind)}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label={t('payment_cascades.fields.traffic_percentage')}>
            <Text style={{ fontWeight: 'bold', }}>{record?.traffic_percentage}</Text>
          </Descriptions.Item>
          <Descriptions.Item label={t('payment_cascades.fields.all_merchants')}>
            <BooleanTag value={record?.all_merchants} />
          </Descriptions.Item>
          <Descriptions.Item label={t('payment_cascades.fields.merchant')}>
            <Text style={{ fontWeight: 'bold', }}>{record?.merchant?.name}</Text>
          </Descriptions.Item>
          <Descriptions.Item label={t('payment_cascades.fields.all_gateways')}>
            <BooleanTag value={record?.all_gateways} />
          </Descriptions.Item>
          <Descriptions.Item label={t('payment_cascades.fields.gateway')}>
            <Space>
              {record?.gateway?.direction && <GatewayDirection direction={record?.gateway?.direction} />}
              {record?.gateway?.payment_type && <GatewayType type={record?.gateway?.payment_type} />}
              {record?.gateway?.currency}
              {record?.gateway?.name}
              {record?.gateway?.payment_method && <GatewayMethod method={record?.gateway?.payment_method} />}
            </Space>
          </Descriptions.Item>
        </Descriptions>
      </Show>
    </>
  )
}
