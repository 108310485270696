import {
  IResourceComponentsProps,
  useTranslate,
  useCan,
  CanReturnType,
} from '@refinedev/core'

import { useState } from 'react'
import { useTable } from '@refinedev/antd'
import { Table, Typography, TableProps, Space, Tag, Flex, Descriptions } from 'antd'
import { IOperation, IPaymentRequisite, IAccountEntry } from 'interfaces'
import { formattedAmount, formattedCardNumber, formattedAccountNumber, formattedPhone, formattedIbanNumber } from 'utils'
import { OperationStatus } from 'pages/cashin/OperationStatus'
import { OperationActions } from './OperationActions'

import DateField from 'components/DateField'
import { ShortId } from 'components/ShortId'
import CardColorAndNumber from 'components/CardColorAndNumber'
import ClientColor from 'components/ClientColor'
import dayjs from 'dayjs'
import { OperationView } from 'components/OperationView'
import { OperationMargin } from 'components/OperationMargin'
import { GatewayType } from 'components/GatewayType'
import { AccountEntriesTable } from 'components/AccountEntriesTable'
import { GatewayDirection } from 'components/GatewayDirection'

const { Text } = Typography

const MoreInfo: React.FC<{ operation: IOperation }> = ({ operation }) => {
  const t = useTranslate()

  const { data: canViewScoringClients } = useCan({
    resource: 'scoring/clients',
    action: 'view',
  })
  const { data: canViewScoringClientId } = useCan({
    resource: 'scoring/clients',
    action: 'view_id',
  })
  const { data: canViewClientId } = useCan({
    resource: 'operations',
    action: 'view_client_id',
  })
  const { data: canApproveOperation } = useCan({
    resource: 'operations',
    action: 'approve',
  })

  const { tableProps } = useTable<IAccountEntry>({
    resource: 'account_entries',
    filters: {
      permanent: [
        {
          field: 'operation_id',
          operator: 'eq',
          value: operation.id,
        },
      ]
    },
    pagination: {
      pageSize: 20,
    },
  })

  return (
    <Flex
      vertical
      gap={15}
    >
      <Descriptions
        layout='vertical'
        bordered
      >
        {canViewClientId?.can &&
          <Descriptions.Item label={t('operations.fields.client_id')}>
            {canViewScoringClients?.can ? (
              <ClientColor color={operation?.client_color} value={operation?.client_id} />
            ) : (
              operation?.client_id
            )}
          </Descriptions.Item>}
        {canViewScoringClientId?.can &&
          <Descriptions.Item label={t('operations.fields.scoring_client_id')}>
            {canViewScoringClients?.can ? (
              <ShortId
                value={operation?.scoring_client_id || ''}
                color={operation?.client_color}
              />
            ) : (
              <ShortId
                value={operation?.scoring_client_id || ''}
              />
            )
            }
          </Descriptions.Item>}
        <Descriptions.Item label={t('operations.fields.approval_time')}>
          {operation.approved_at ? dayjs(operation.approved_at).diff(dayjs(operation.created_at), 'minutes') : ''}
        </Descriptions.Item>
        <Descriptions.Item label={t('operations.fields.comment')}>
          {operation.comment}
        </Descriptions.Item>
      </Descriptions>
      <AccountEntriesTable tableProps={tableProps} />
    </Flex>
  )
}

const OperationsTable: React.FC<
  IResourceComponentsProps & {
    tableProps: TableProps<IOperation>
    canViewMerchants: CanReturnType | undefined
  }
> = ({ tableProps, canViewMerchants }) => {
  const t = useTranslate()

  const { data: canApproveOperation } = useCan({
    resource: 'operations',
    action: 'approve',
  })
  const { data: canViewPartners } = useCan({
    resource: 'partners',
    action: 'filter',
  })
  const { data: canViewOperation } = useCan({
    resource: 'operations',
    action: 'view',
  })
  const { data: canUpdatePaymentRequisite } = useCan({
    resource: 'operations',
    action: 'update_payment_requisite',
  })
  const { data: canViewFee } = useCan({
    resource: 'operations',
    action: 'view_fee',
  })
  const { data: canViewMargin } = useCan({
    resource: 'operations',
    action: 'margin',
  })

  const [expandedKey, setExpandedKey] = useState(String)
  const onExpand = (_: any, record: IOperation) => {
    expandedKey === record.id ? setExpandedKey('') : setExpandedKey(record.id)
  }

  return (
    <Table
      scroll={{ x: '100%' }}
      {...tableProps}
      size="small"
      rowKey="id"
      expandable={{
        expandedRowRender: (operation) => (
          <MoreInfo operation={operation} />
        ),
        onExpand,
        expandedRowKeys: [expandedKey]
      }}
      pagination={{
        ...tableProps.pagination,
        position: ['bottomLeft'],
        size: 'small',
      }}
    >
      <Table.Column
        ellipsis={true}
        dataIndex="id"
        key="id"
        title={t('operations.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column
        width={10}
        dataIndex="status"
        key="status"
        title={t('operations.fields.status')}
        render={(value, record) => (
          <>
            <Space
              direction='vertical'
              size={2}
            >
              <OperationStatus status={value} />
              {record?.gateway?.payment_type === 'ecom' &&
                <Tag>{record.ecom_status}</Tag>
              }
            </Space>
          </>
        )}
      />
      <Table.Column
        width={50}
        dataIndex="idempotency_key"
        key="idempotency_key"
        title={t('operations.fields.idempotency_key')}
      />
      <Table.Column
        ellipsis
        dataIndex={['gateway', 'currency']}
        key="currency"
        title={t('operations.fields.currency')}
      />
      <Table.Column
        width={50}
        align="right"
        dataIndex="amount"
        key="amount"
        title={t('operations.fields.amount')}
        render={formattedAmount}
      />
      <Table.Column
        width={50}
        align="right"
        dataIndex="approved_amount"
        key="approved_amount"
        title={t('operations.fields.approved_amount')}
        render={(value: string, record: IOperation) => (
          <Text mark={value ? Number(value) !== Number(record?.amount) : false}>
            {formattedAmount(value)}
          </Text>
        )}
      />
      {canViewFee?.can && (
        <Table.Column<IOperation>
          ellipsis
          width={50}
          align="right"
          dataIndex="fee"
          key="fee"
          title={t('operations.fields.fee')}
          render={(value: string, record: IOperation) => (
            <Text
              style={{
                color: Number(value) <= Number(record?.margin?.cost) ? 'red' : '',
                fontWeight: Number(value) <= Number(record?.margin?.cost) ? 'bold' : ''
              }}
            >
              {formattedAmount(value)}
            </Text>
          )}
        />
      )}
      {canViewMargin?.can && (
        <>
          <Table.Column<IOperation>
            ellipsis
            width={50}
            align="right"
            dataIndex={['margin', 'cost']}
            key="margin"
            title={t('operations.fields.cost')}
            render={(value: string) => (
              <Text
                style={{
                  color: Number(value) <= 0 ? 'red' : ''
                }}
              >
                {formattedAmount(value)}
              </Text>
            )}
          />
          <Table.Column<IOperation>
            dataIndex="margin"
            key="margin"
            render={(_value, record) => record.approved_amount !== null && <OperationMargin id={record.id} />}
          />
        </>
      )}
      {canViewMerchants?.can && (
        <Table.Column
          ellipsis
          dataIndex={['merchant', 'name']}
          key="merchant"
          title={t('operations.fields.merchant')}
        />
      )}
      <Table.Column<IOperation>
        ellipsis
        dataIndex={'payment_requisite'}
        key="payment_requisite"
        title={t('operations.fields.card')}
        render={(value: IPaymentRequisite) => {
          if (value) {
            var text = ''
            if (value.sbp) {
              text = formattedPhone(value.sbp.phone)
            } else if (value.account) {
              text = formattedAccountNumber(value.account)
            } else if (value.iban) {
              text = formattedIbanNumber(value.iban)
            } else {
              text = formattedCardNumber(value.card_number)
            }

            if (canUpdatePaymentRequisite?.can) {
              return <CardColorAndNumber number={text} color={value.color} />
            } else {
              return text
            }
          } else {
            return ''
          }
        }}
      />
      <Table.Column
        ellipsis
        dataIndex={['gateway', 'payment_type']}
        key="payment_type"
        title={t('operations.fields.type')}
        render={(value, record) => (
          <Space>
            <GatewayDirection direction={record.gateway.direction} />
            <GatewayType type={value} />
          </Space>
        )}
      />
      <Table.Column
        ellipsis
        dataIndex={['gateway', 'name']}
        key="gateway_name"
        title={t('operations.fields.gateway')}
      />
      {canViewPartners?.can && (
        <Table.Column<IOperation>
          ellipsis
          dataIndex={['partner', 'name']}
          key="partner"
          title={t('operations.fields.partner')}
        />
      )}
      {canViewPartners?.can && (
        <Table.Column<IOperation>
          dataIndex={['payment_cascade', 'payment_system']}
          key="payment_cascade"
          title={t('operations.fields.payment_system')}
        />
      )}
      <Table.Column
        ellipsis={true}
        dataIndex="trade_order_id"
        key="trade_order_id"
        title={t('operations.fields.trade_order_id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IOperation>
        ellipsis
        width={10}
        key="datetimes"
        title={t('operations.fields.datetimes')}
        render={(_, record) => (
          <Space
            direction='vertical'
            size={1}
            style={{ fontSize: 12 }}
          >
            <Space>
              {t('operations.fields.created_at')}
              <DateField
                value={record?.created_at}
                style={{ fontSize: 12 }}
              />
            </Space>
            <Space>
              {t('operations.fields.approved_at')}
              <DateField
                value={record?.approved_at}
                style={{ fontSize: 12, color: 'green' }}
              />
            </Space>
            <Space>
              {t('operations.fields.provider_approved_at')}
              <DateField
                value={record?.provider_approved_at}
                style={{ fontSize: 12, color: 'blue' }}
              />
            </Space>
          </Space>
        )}
      />
      {(canApproveOperation?.can || canUpdatePaymentRequisite?.can || canViewOperation?.can) && (
        <Table.Column<IOperation>
          fixed="right"
          title={t('operations.fields.actions')}
          dataIndex="actions"
          key="actions"
          align="center"
          render={(_value, record) => (
            <Space
              direction='vertical'
            >
              {record.status !== 'wasted' && <OperationActions id={record.id} />}
              <OperationView id={record.id} />
            </Space>
          )}
        />
      )}
    </Table>
  )
}

export default OperationsTable
