import React from 'react'
import { useTable } from '@refinedev/antd'
import { IAccountOperation } from 'interfaces'
import AccountOperationsTable from 'pages/account_operations/list_table'

export const AccountOperationsList: React.FC<{ providerId: string }> = ({ providerId }) => {
  const { tableProps } = useTable<IAccountOperation>({
    resource: 'account_operations',
    filters: {
      permanent: [
        {
          field: 'provider_id',
          operator: 'eq',
          value: providerId,
        },
      ],
    },
  })

  return (
    <>
      <AccountOperationsTable tableProps={tableProps} />
    </>
  )
}
