import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Edit, useForm } from '@refinedev/antd'
import { Checkbox, Form, Input } from 'antd'

import { ITeam } from 'interfaces'

export const TeamEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<ITeam>()

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t('teams.fields.name')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="name" />
        </Form.Item>
        <Form.Item label={t('teams.fields.telegram_chat_id')} name="telegram_chat_id">
          <Input />
        </Form.Item>
        <Form.Item
          label={t('teams.fields.trade_orders_enabled')}
          name="trade_orders_enabled"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Edit>
  )
}
