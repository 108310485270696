import { IResourceComponentsProps, useTranslate, useList } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form, Input, Select } from "antd";
import { gatewayDirectionOptions, gatewayPaymentMethodOptions, gatewayPaymentTypeOptions, generalStatusOptions } from 'utils'
import { IGateway, ICurrency } from 'interfaces'

export const GatewayCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<IGateway>()

  const { data: currenciesData } = useList<ICurrency>({
    resource: 'currencies',
    pagination: {
      current: 1,
      pageSize: 1000,
    },
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
  })
  const currencies = currenciesData?.data ?? []

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t('gateways.fields.name')}
          name="name"
          rules={[{ required: true, },]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('gateways.fields.currency')}
          name="currency"
          rules={[{ required: true, },]}
        >
          <Select
            options={currencies.map((currency) => ({
              label: currency.code,
              value: currency.code,
            }))}
          />
        </Form.Item>
        <Form.Item
          label={t('gateways.fields.direction')}
          name="direction"
          rules={[{ required: true, message: '' },]}
        >
          <Select options={gatewayDirectionOptions} />
        </Form.Item>
        <Form.Item
          label={t('gateways.fields.payment_method')}
          name="payment_method"
          rules={[{ required: true, message: '' },]}
        >
          <Select options={gatewayPaymentMethodOptions} />
        </Form.Item>
        <Form.Item
          label={t('gateways.fields.payment_type')}
          name="payment_type"
          rules={[{ required: true, message: '' },]}
        >
          <Select options={gatewayPaymentTypeOptions} />
        </Form.Item>
        <Form.Item
          label={t('gateways.fields.status')}
          name="status"
          rules={[{ required: true, message: '' },]}
        >
          <Select options={generalStatusOptions} />
        </Form.Item>
        <Form.Item
          label={t('gateways.fields.description')}
          name="description"
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  )
}
