import React from 'react'
import {
  CrudFilters,
  getDefaultFilter,
  IResourceComponentsProps,
  useTranslate,
} from '@refinedev/core'
import { Form, Input, Segmented } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { List, useTable } from '@refinedev/antd'
import { IProviderGateway } from 'interfaces'
import ProviderGatewaysTable from './list_table'
import { generalStatusOptions } from 'utils'

export const ProviderGatewaysList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { tableProps, searchFormProps, filters } = useTable<IProviderGateway>({
    onSearch: (params: any) => {
      const { id, name, external_method, provider_name, provider_id, gateway_id, status } = params
      const filters: CrudFilters = []

      filters.push({
        field: 'id',
        operator: 'eq',
        value: id ? id : null,
      })

      filters.push({
        field: 'name',
        operator: 'eq',
        value: name ? name : null,
      })

      filters.push({
        field: 'external_method',
        operator: 'eq',
        value: external_method ? external_method : null,
      })

      filters.push({
        field: 'provider_name',
        operator: 'eq',
        value: provider_name ? provider_name : null,
      })

      filters.push({
        field: 'provider_id',
        operator: 'eq',
        value: provider_id ? provider_id : null,
      })

      filters.push({
        field: 'gateway_id',
        operator: 'eq',
        value: gateway_id ? gateway_id : null,
      })

      filters.push({
        field: 'status',
        operator: 'eq',
        value: status ? status : null,
      })

      return filters
    },

    pagination: {
      pageSize: 30,
    },
  })

  var timerId: any

  const debounceFunction = (func: any, delay: number) => {
    clearTimeout(timerId)
    timerId = setTimeout(func, delay)
  }

  const onValueChange = () => {
    debounceFunction(() => {
      searchFormProps.form?.submit()
    }, 400)
  }

  return (
    <List>
      <Form
        {...searchFormProps}
        layout="vertical"
        size={'middle'}
        onValuesChange={onValueChange}
        initialValues={{
          id: getDefaultFilter('id', filters),
          name: getDefaultFilter('name', filters),
          external_method: getDefaultFilter('external_method', filters),
          provider_name: getDefaultFilter('provider_name', filters),
          provider_id: getDefaultFilter('provider_id', filters),
          gateway_id: getDefaultFilter('gateway_id', filters),
          status: getDefaultFilter('status', filters),
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: 10,
          }}>
          <Form.Item
            name="id"
            style={{
              flexGrow: 1,
              marginBottom: 5,
            }}
          >
            <Input
              placeholder={t('provider_gateways.filter.id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="name"
            style={{
              flexGrow: 1,
              marginBottom: 5,
            }}
          >
            <Input
              placeholder={t('provider_gateways.filter.name.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="external_method"
            style={{
              flexGrow: 1,
              marginBottom: 5,
            }}
          >
            <Input
              placeholder={t('provider_gateways.filter.external_method.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: 10,
          }}>
          <Form.Item
            name="provider_id"
            style={{
              flexGrow: 1,
              marginBottom: 5,
            }}
          >
            <Input
              placeholder={t('provider_gateways.filter.provider_id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="provider_name"
            style={{
              flexGrow: 1,
              marginBottom: 5,
            }}
          >
            <Input
              placeholder={t('provider_gateways.filter.provider_name.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="gateway_id"
            style={{
              flexGrow: 1,
              marginBottom: 5,
            }}
          >
            <Input
              placeholder={t('provider_gateways.filter.gateway_id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
        </div>
        <div
          style={{
            overflow: 'auto',
          }}
        >
          <Form.Item name="status" style={{ marginBottom: 5, }}>
            <Segmented
              options={[
                { label: 'ALL', value: '' },
                ...generalStatusOptions.map((option) => ({
                  label: option.label?.toLocaleUpperCase(),
                  value: option.value,
                }))
              ]}
            />
          </Form.Item>
        </div>
      </Form>
      <ProviderGatewaysTable tableProps={tableProps} />
    </List>
  )
}
