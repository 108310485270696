import React from "react";

export const AppIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          d="M10.452 11.068c1.362-.89 2.463-1.904 3.316-3.06C15.306 5.924 16 3.428 16 .5a.5.5 0 0 0-.5-.5c-2.929 0-5.423.694-7.508 2.232-1.156.853-2.17 1.954-3.06 3.316l-.785-.181a2.5 2.5 0 0 0-2.798 1.318L.053 9.276A.5.5 0 0 0 .5 10l3.03-.379a2 2 0 0 1 1.662.57l.616.617a2 2 0 0 1 .57 1.663L6 15.5a.5.5 0 0 0 .724.447l2.591-1.296a2.5 2.5 0 0 0 1.318-2.798zM11.5 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          clipRule="evenodd"
        />
        <path d="M.773 12.597c.433-1.586 2.418-2.113 3.58-.95 1.163 1.162.636 3.147-.95 3.58l-2.771.755a.5.5 0 0 1-.614-.614z" />
      </g>
    </svg>
  );
};
