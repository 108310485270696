import React from 'react'
import { IResourceComponentsProps, useCan, useShow, useTranslate } from '@refinedev/core'
import { Show } from '@refinedev/antd'
import { Descriptions, Tabs, TabsProps, Space, Typography, Flex } from 'antd'

import { IPartner } from 'interfaces'
import { AgreementsList } from './agreements_list'
import BooleanTag from 'components/BooleanTag'
import {
  formattedCorrectedPercent,
  formattedCurrencyAmount,
  formattedPercent,
} from 'utils'

import { GatewayType } from 'components/GatewayType'
import { GatewayDirection } from 'components/GatewayDirection'
import { GatewayMethod } from 'components/GatewayMethod'

const { Text } = Typography

export const PartnerShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { queryResult } = useShow<IPartner>()
  const { data, isLoading } = queryResult
  const record = data?.data

  let tabsItems: TabsProps['items'] = []
  tabsItems.push({
    key: '1',
    label: t('partners.titles.agreements'),
    children: record && !isLoading ? <AgreementsList parntnerId={record.id} currency={record?.currency} /> : null,
  })

  const { data: canViewAgreements } = useCan({
    resource: 'billing/agreements',
    action: 'list',
  })

  return (
    <>
      <Show
        isLoading={isLoading}
        title={record?.name || ''}
        canEdit={true}
      >
        <Flex
          vertical
          gap={40}
        >
          <Descriptions
            column={1}
            bordered
            style={{
              maxWidth: 800,
            }}
          >
            <Descriptions.Item label={t('partners.fields.deposit_balance')}>
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: 22,
                }}
              >
                {record && formattedCurrencyAmount(record?.deposit_balance, 2, record.currency)}
              </Text>
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            column={1}
            bordered
            style={{
              maxWidth: 800,
            }}
          >
            <Descriptions.Item label={t('partners.fields.name')}>
              {record?.name}
            </Descriptions.Item>
            <Descriptions.Item label={t('partners.fields.currency')}>
              {record?.currency}
            </Descriptions.Item>
            <Descriptions.Item label={t('partners.fields.telegram_chat_id')}>
              {record?.telegram_chat_id}
            </Descriptions.Item>
            <Descriptions.Item label={t('partners.fields.notifications_chat_id')}>
              {record?.notifications_chat_id}
            </Descriptions.Item>
            <Descriptions.Item label={t('partners.fields.payouts_enabled')}>
              <BooleanTag value={record?.payouts_enabled} />
            </Descriptions.Item>
            <Descriptions.Item label={t('partners.fields.max_approved_sum')}>
              {record?.max_approved_sum
                ? formattedCurrencyAmount(record?.max_approved_sum)
                : t('partners.titles.no_limit')}
            </Descriptions.Item>
            <Descriptions.Item label={t('partners.fields.cash_limit_per_requisite')}>
              {record?.cash_limit_per_requisite
                ? formattedCurrencyAmount(record?.cash_limit_per_requisite)
                : t('partners.titles.no_limit')}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            layout='vertical'
            column={1}
            bordered
            style={{
              maxWidth: 800,
            }}
          >
            <Descriptions.Item label={t('partners.fields.agreements_income')}>
              <div>
                {record?.agreements_income?.length ? (
                  record?.agreements_income.map((agreement) => (
                    <Text
                      key={agreement?.gateway}
                      code
                      ellipsis
                      style={{ display: 'flex', alignItems: 'center', margin: 4, }}
                    >
                      <Space>
                        {agreement?.gateway ? (
                          <Space>
                            <GatewayDirection direction={agreement.gateway.direction} />
                            <GatewayType type={agreement.gateway.payment_type} />
                            {agreement.gateway.currency.code}
                            {agreement.gateway.name}
                            <GatewayMethod method={agreement.gateway.payment_method} />
                          </Space>
                        ) : <Text>{t('partners.titles.gateway_not_specified')}</Text>}
                        {formattedCorrectedPercent(agreement.percent)}
                      </Space>
                    </Text>
                  ))) : <Text>{t('partners.titles.no_agreements')}</Text>
                }
              </div>
            </Descriptions.Item>
            <Descriptions.Item label={t('partners.fields.agreements_payout')}>
              <div>
                {record?.agreements_payout?.length ? (
                  record?.agreements_payout.map((agreement) => (
                    <Text
                      key={agreement?.gateway}
                      code
                      ellipsis
                      style={{ display: 'flex', alignItems: 'center', margin: 4, }}
                    >
                      <Space>
                        {agreement?.gateway ? (
                          <Space>
                            <GatewayDirection direction={agreement.gateway.direction} />
                            <GatewayType type={agreement.gateway.payment_type} />
                            {agreement.gateway.currency.code}
                            {agreement.gateway.name}
                            <GatewayMethod method={agreement.gateway.payment_method} />
                          </Space>
                        ) : <Text>{t('partners.titles.gateway_not_specified')}</Text>}
                        {formattedCorrectedPercent(agreement.percent)}
                      </Space>
                    </Text>
                  ))) : <Text>{t('partners.titles.no_agreements')}</Text>
                }
              </div>
            </Descriptions.Item>
          </Descriptions>
        </Flex>
      </Show>
      {canViewAgreements?.can && <Tabs defaultActiveKey="1" items={tabsItems} />}
    </>
  )
}
