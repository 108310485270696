import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Edit, ListButton, useForm } from '@refinedev/antd'
import { Form, Input, Select, } from 'antd'
import { IProvider } from 'interfaces'
import { generalStatusOptions } from 'utils'

export const ProviderEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps, form } = useForm<IProvider>({
    action: 'edit',
    redirect: 'show',
  })

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    try {
      JSON.parse(e.target.value);
      form.setFieldsValue({
        credentials: JSON.stringify(JSON.parse(e.target.value), null, 2)
      })
    } catch { }
  };

  return (
    <Edit
      headerButtons={<ListButton />}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t('providers.fields.status')}
          name="status"
        >
          <Select options={generalStatusOptions} />
        </Form.Item>
        <Form.Item
          label={t('providers.fields.description')}
          name="description"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('providers.fields.credentials')}
          name="credentials"
        >
          <Input.TextArea
            rows={15}
            onChange={handleChange}
            style={{ fontFamily: "monospace" }}
          />
        </Form.Item>
      </Form>
    </Edit>
  )
}
