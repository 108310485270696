import { Button, Popconfirm, Space, Table, TableProps, Tag } from 'antd'
import { IOrder } from '../../interfaces'
import { TextField } from '@refinedev/antd'
import { IResourceComponentsProps, useTranslate, usePermissions } from '@refinedev/core'
import { CloseCircleFilled, CloseCircleOutlined } from '@ant-design/icons'
import { ShortId } from 'components/ShortId'
import DateField from 'components/DateField'
import { Grid, Flex } from 'antd'
import { formattedAmount, formattedCorrectedPercent } from 'utils'

export const OrdersTable: React.FC<
  IResourceComponentsProps & {
    tableProps: TableProps<IOrder>
    onCancel: (id: string) => void
  }
> = ({ tableProps, onCancel }) => {
  const t = useTranslate()

  const breakpoint = Grid.useBreakpoint();
  const isMobile = typeof breakpoint.lg === "undefined" ? true : !breakpoint.lg;

  const { data } = usePermissions<string>()
  const isAdmin = data?.includes('admin')

  return (
    <Table
      {...tableProps}
      size="small"
      rowKey="id"
      pagination={
        tableProps.pagination && (tableProps.pagination?.total ?? 0) > 20
          ? {
            ...tableProps.pagination,
            pageSize: 20,
            position: ['bottomLeft'],
            size: 'small',
          }
          : false
      }
      onRow={(record, _) => ({
        style: {
          background: !isAdmin && record.created_at ? '#f9f9f9' : 'default',
        }
      })}
      style={{
        maxHeight: 400,
        overflow: 'auto',
        marginBottom: 20,
      }}
    >
      {isMobile ? (
        <>
          <Table.Column<IOrder>
            title={null}
            render={(_, record) => (
              <Flex
                vertical
                gap={6}
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                {isAdmin &&
                  <Flex justify='space-between'>
                    <span>{t('trade_orders.fields.id')}</span>
                    <ShortId value={record.id} />
                  </Flex>
                }
                {isAdmin &&
                  <Flex justify='space-between'>
                    <span>{t('trade_orders.fields.team')}</span>
                    <span style={{ fontWeight: 'bold' }}>{record?.team?.name}</span>
                  </Flex>
                }
                {isAdmin &&
                  <Flex justify='space-between'>
                    <span>{t('trade_orders.fields.status')}</span>
                    <Tag color={record?.status === 'active' ? 'green' : 'red'}>{record?.status}</Tag>
                  </Flex>
                }
                <Flex justify='space-between'>
                  <span>{t('trade_orders.fields.kind')}</span>
                  <Tag color={record?.kind === 'income' ? 'blue' : 'red'}>{record?.kind}</Tag>
                </Flex>
                <Flex justify='space-between'>
                  <span>{t('trade_orders.fields.gateway')}</span>
                  <span style={{ fontWeight: 'bold' }}>{record?.gateway?.name}</span>
                </Flex>
                <Flex justify='space-between'>
                  <span>{t('trade_orders.fields.currency')}</span>
                  <span style={{ fontWeight: 'bold' }}>{record?.currency}</span>
                </Flex>
                {isAdmin &&
                  <Flex justify='space-between'>
                    <span>{t('trade_orders.fields.initial_amount')}</span>
                    <span>{formattedAmount(record?.initial_amount)}</span>
                  </Flex>
                }
                <Flex justify='space-between'>
                  <span>{t('trade_orders.fields.current_amount')}</span>
                  <span style={{ fontWeight: 'bold' }}>{formattedAmount(record?.current_amount)}</span>
                </Flex>
                <Flex justify='space-between'>
                  <span>{t('trade_orders.fields.percent')}</span>
                  <span style={{ fontWeight: 'bold' }}>{formattedCorrectedPercent(record?.percent)}</span>
                </Flex>
                {isAdmin &&
                  <Flex justify='space-between'>
                    <span>{t('trade_orders.fields.created_at')}</span>
                    <DateField value={record?.created_at} />
                  </Flex>
                }
                {isAdmin &&
                  <Flex justify='space-between'>
                    <span>{t('trade_orders.fields.updated_at')}</span>
                    <DateField value={record?.updated_at} />
                  </Flex>
                }
                {(isAdmin || record.created_at) &&
                  <Flex justify='center' style={{ marginTop: 20, }}>
                    <Space size="middle">
                      <Popconfirm
                        title={t('trade_orders.confirm.cancel_trade_order.title')}
                        description={t('trade_orders.confirm.cancel_trade_order.description')}
                        onConfirm={() => {
                          onCancel(record.id)
                        }}
                        okText={t('trade_orders.confirm.cancel_trade_order.ok')}
                        cancelText={t('trade_orders.confirm.cancel_trade_order.cancel')}
                      >
                        <Button danger icon={<CloseCircleOutlined />}>{t('trade_orders.actions.cancel')}</Button>
                      </Popconfirm>
                    </Space>
                  </Flex>
                }
              </Flex>
            )}
          />
        </>
      ) : (
        <>
          {isAdmin &&
            <Table.Column<IOrder>
              dataIndex={'id'}
              ellipsis
              key="id"
              title={t('trade_orders.fields.id')}
              render={(value) => <ShortId value={value} />}
              width={1}
            />
          }
          {isAdmin &&
            <Table.Column<IOrder>
              dataIndex={['team', 'name']}
              ellipsis
              key="team_name"
              title={t('trade_orders.fields.team')}
              width={1}
            />
          }
          {isAdmin &&
            <Table.Column<IOrder>
              dataIndex={'status'}
              ellipsis
              key="status"
              title={t('trade_orders.fields.status')}
              render={(value) => <Tag color={value === 'active' ? 'green' : 'red'}>{value}</Tag>}
              width={1}
            />
          }
          <Table.Column<IOrder>
            dataIndex={'kind'}
            ellipsis
            key="kind"
            title={t('trade_orders.fields.kind')}
            render={(value) => <Tag color={value === 'income' ? 'blue' : 'red'}>{value}</Tag>}
            width={1}
          />
          <Table.Column<IOrder>
            dataIndex={['gateway', 'name']}
            ellipsis
            key="gateway"
            title={t('trade_orders.fields.gateway')}
            render={(value) => (
              <TextField
                style={{ color: value ? '' : 'grey' }}
                value={value ? value : t('partners.titles.gateway_not_specified')}
              />
            )}
            width={1}
          />
          <Table.Column<IOrder>
            dataIndex={'currency'}
            ellipsis
            key="currency"
            title={t('trade_orders.fields.currency')}
            width={1}
          />
          {isAdmin &&
            <Table.Column<IOrder>
              dataIndex={'initial_amount'}
              ellipsis
              key="initial_amount"
              title={t('trade_orders.fields.initial_amount')}
              render={formattedAmount}
              width={1}
            />
          }
          <Table.Column<IOrder>
            dataIndex={'current_amount'}
            ellipsis
            key="current_amount"
            title={t('trade_orders.fields.current_amount')}
            render={formattedAmount}
            width={1}
          />
          <Table.Column<IOrder>
            dataIndex={'percent'}
            ellipsis
            key="percent"
            title={t('trade_orders.fields.percent')}
            render={formattedCorrectedPercent}
          />
          {isAdmin &&
            <Table.Column<IOrder>
              dataIndex="created_at"
              ellipsis
              key="created_at"
              title={t('trade_orders.fields.created_at')}
              render={(value) => <DateField value={value} />}
            />
          }
          {isAdmin &&
            <Table.Column<IOrder>
              dataIndex="updated_at"
              ellipsis
              key="updated_at"
              title={t('trade_orders.fields.updated_at')}
              render={(value) => <DateField value={value} />}
            />
          }
          <Table.Column<IOrder>
            dataIndex="actions"
            ellipsis
            key="actions"
            width={'1rem'}
            render={(_, record) => (
              (isAdmin || record.created_at) ? (
                <Space size="middle">
                  <Popconfirm
                    title={t('trade_orders.confirm.cancel_trade_order.title')}
                    description={t('trade_orders.confirm.cancel_trade_order.description')}
                    onConfirm={() => {
                      onCancel(record.id)
                    }}
                    okText={t('trade_orders.confirm.cancel_trade_order.ok')}
                    cancelText={t('trade_orders.confirm.cancel_trade_order.cancel')}
                  >
                    <Button danger icon={<CloseCircleOutlined />}>{t('trade_orders.actions.cancel')}</Button>
                  </Popconfirm>
                </Space>
              ) : (
                <></>
              )
            )}
          />
        </>
      )}
    </Table>
  )
}