import React from 'react'
import { useTranslate } from '@refinedev/core'
import { IShift } from 'interfaces'
import { formattedCurrencyAmount } from 'utils'
import { Flex, Typography, Grid } from 'antd'

const { Text } = Typography

export const Stats: React.FC<{ shift: IShift }> = ({ shift }) => {
  const t = useTranslate()

  const breakpoint = Grid.useBreakpoint();
  const isMobile = typeof breakpoint.lg === "undefined" ? true : !breakpoint.lg;

  const currency = shift.partner.currency
  const approved_operations_amount = formattedCurrencyAmount(shift.approved_operations_amount, 0, currency)
  const approved_payouts_amount = formattedCurrencyAmount(shift.approved_payouts_amount, 0, currency)
  const deposit_balance = shift.partner.deposit_balance ?
    formattedCurrencyAmount(shift.partner.deposit_balance, 0, currency) : '0'

  return (
    <Flex
      vertical={isMobile}
      gap={isMobile ? 5 : 10}
    >
      <Text>
        {t('components.shift.payins')}:&nbsp;
        <Text style={{ fontWeight: 'bold' }}>
          {approved_operations_amount}&nbsp;({shift.approved_operations_count})
        </Text>
      </Text>
      <Text>
        {t('components.shift.payouts')}:&nbsp;
        <Text style={{ fontWeight: 'bold' }}>
          {approved_payouts_amount}&nbsp;({shift.approved_payouts_count})
        </Text>
      </Text>
      {deposit_balance &&
        <Text>
          {t('components.shift.balance')}:&nbsp;
          <Text style={{ fontWeight: 'bold' }}>
            {deposit_balance}
          </Text>
        </Text>
      }
    </Flex>
  )
}
