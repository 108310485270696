import {
  CrudFilters,
  getDefaultFilter,
  IResourceComponentsProps,
  useTranslate,
  useList
} from '@refinedev/core'

import { List, useTable, } from '@refinedev/antd'
import AccountOperationsTable from './list_table'
import { IAccountOperation, } from 'interfaces'
import { Form, Input, Flex, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { AccountOperationStatus } from 'components/AccountOperationStatus'
import { AccountOperationKind } from 'components/AccountOperationKind'

export const AccountOperationsList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { tableProps, searchFormProps, filters } = useTable<IAccountOperation>({
    resource: 'account_operations',

    onSearch: (params: any) => {
      const { id, account_id, kind, status, merchant_id, agent_id, team_id } = params
      const filters: CrudFilters = []

      filters.push({
        field: 'id',
        operator: 'eq',
        value: id,
      })

      filters.push({
        field: 'account_id',
        operator: 'eq',
        value: account_id,
      })

      filters.push({
        field: 'kind',
        operator: 'eq',
        value: kind,
      })

      filters.push({
        field: 'status',
        operator: 'eq',
        value: status,
      })

      filters.push({
        field: 'merchant_id',
        operator: 'eq',
        value: merchant_id,
      })

      filters.push({
        field: 'agent_id',
        operator: 'eq',
        value: agent_id,
      })

      filters.push({
        field: 'team_id',
        operator: 'eq',
        value: team_id,
      })

      return filters
    },

    pagination: {
      pageSize: 20,
    },
  })

  var timerId: any
  const debounceFunction = (func: any, delay: number) => {
    clearTimeout(timerId)
    timerId = setTimeout(func, delay)
  }
  const onValueChange = () => {
    debounceFunction(() => {
      searchFormProps.form?.submit()
    }, 400)
  }

  return (
    <List
      headerButtons={<></>}
    >
      <Form
        {...searchFormProps}
        layout="vertical"
        size={'middle'}
        onValuesChange={onValueChange}
        initialValues={{
          id: getDefaultFilter('id', filters),
          account_id: getDefaultFilter('account_id', filters),
          kind: getDefaultFilter('kind', filters),
          status: getDefaultFilter('status', filters),
          merchant_id: getDefaultFilter('merchant_id', filters),
          agent_id: getDefaultFilter('agent_id', filters),
          team_id: getDefaultFilter('team_id', filters),
        }}
      >
        <Flex
          gap={5}
          wrap
        >
          <Form.Item
            name="id"
            style={{
              flexGrow: 1,
            }}
          >
            <Input
              placeholder={t('account_operations.filter.id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="account_id"
            style={{
              flexGrow: 1,
            }}
          >
            <Input
              placeholder={t('account_operations.filter.account_id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="kind"
            style={{
              flexGrow: 1,
            }}
          >
            <Select
              options={[
                {
                  label: <AccountOperationKind kind={'deposit'} />,
                  value: 'deposit',
                },
                {
                  label: <AccountOperationKind kind={'withdraw'} />,
                  value: 'withdraw',
                }
              ]}
              allowClear
              showSearch
              placeholder={t('account_operations.filter.kind.placeholder')}
            />
          </Form.Item>
          <Form.Item
            name="status"
            style={{
              flexGrow: 1,
            }}
          >
            <Select
              options={[
                {
                  label: <AccountOperationStatus status={'created'} />,
                  value: 'created',
                },
                {
                  label: <AccountOperationStatus status={'approved'} />,
                  value: 'approved',
                },
                {
                  label: <AccountOperationStatus status={'cancelled'} />,
                  value: 'cancelled',
                },
              ]}
              allowClear
              showSearch
              // mode="multiple"
              placeholder={t('account_operations.filter.status.placeholder')}
            />
          </Form.Item>
        </Flex>
        <Flex
          gap={5}
          wrap
        >
          <Form.Item
            name="merchant_id"
            style={{
              flexGrow: 1,
            }}
          >
            <Input
              placeholder={t('accounts.filter.merchant_id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="team_id"
            style={{
              flexGrow: 1,
            }}
          >
            <Input
              placeholder={t('accounts.filter.team_id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="agent_id"
            style={{
              flexGrow: 1,
            }}
          >
            <Input
              placeholder={t('accounts.filter.agent_id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
        </Flex>

      </Form>
      <AccountOperationsTable tableProps={tableProps} />
    </List>
  )
}
