import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Edit, ListButton, useForm } from '@refinedev/antd'
import { Form, Input, Select, } from 'antd'
import { IProviderGateway } from 'interfaces'
import { generalStatusOptions } from 'utils'

export const ProviderGatewayEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps, form } = useForm<IProviderGateway>({
    action: 'edit',
    redirect: 'show',
  })

  return (
    <Edit
      headerButtons={<ListButton />}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t('provider_gateways.fields.external_method')}
          name="external_method"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('provider_gateways.fields.name')}
          name="name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('provider_gateways.fields.status')}
          name="status"
        >
          <Select options={generalStatusOptions} />
        </Form.Item>
      </Form>
    </Edit>
  )
}
