import React from 'react'
import { gatewayTypes } from 'interfaces'
import { Tag } from 'antd'

export const GatewayType: React.FC<{ type: gatewayTypes }> = ({ type }) => {

  const GatewayTypeColorMap = {
    card: 'blue',
    sbp: 'green',
    ecom: 'orange',
    account: 'pink',
    payout: 'red',
    outcome: 'grey',
    refill: 'yellow',
    iban: 'cyan-inverse',
    sbpqr: 'green',
    qr: 'green',
    url: 'green-inverse',
    cvu: 'cyan-inverse',
    va: 'cyan-inverse',
    phone: 'green'
  }

  return (
    <Tag
      color={GatewayTypeColorMap[type]}
      style={{ fontSize: 14 }}
    >
      {type}
    </Tag>
  )
}
